<div class="articles-list-wrapper" (click)="bgClick()">
  <mat-spinner *ngIf="loading; else Content" [diameter]="40" [strokeWidth]="4"></mat-spinner>

  <ng-template #Content>
    <div class="articles-list">
      <div *ngIf="!articles?.length" class="empty-list-info">
        <nz-empty></nz-empty>
      </div>

      <div *ngFor="let article of articles" class="article-item-wrapper">
        <app-article-list-item [article]="article"></app-article-list-item>
      </div>
    </div>
  </ng-template>
</div>
