<div class="page-container">
  <div class="column left" *ngIf="deviceInfo.deviceTypeDetected === 'DESKTOP'">
    <div class="texts-container">
      <h1 class="main-title-text" [innerHTML]="'NEW_ACCOUNT.MAIN_TITLE' | translate: {}"></h1>
      <h3 class="subtitle-text" [innerHTML]="'NEW_ACCOUNT.SUBTITLE' | translate: {}"></h3>
    </div>
  </div>
  <ng-container *ngIf="APIAvailable; else ApiNotAvailable">
    <div
      class="column right"
      *ngIf="currentUserLang && (loadingSelfRegisterActive$ | async) === false; else userLangLoader">
      <div class="utils-buttons-wrapper">
        <app-utils-buttons (userLangChanged)="userLangChanged($event)"></app-utils-buttons>
      </div>

      <div class="logo">
        <img src="https://kartapobytu.app/img/logo.png" />
      </div>

      <!-- OPEN REGISTRATION DISABLED -->
      <div class="content-column" *ngIf="!selfRegisterActive">
        <div class="row-wrapper inactive-registration">
          <div nz-row>
            <div nz-col [nzXs]="23" [nzSm]="23" [nzMd]="21">
              <div class="form-title" [innerHTML]="'NEW_ACCOUNT.REGISTRATION_TEMPORARILY_INACTIVE' | translate"></div>
            </div>
          </div>
          <div nz-row>
            <div nz-col [nzXs]="23" [nzSm]="23" [nzMd]="21">
              <div class="explanation-text" [innerHTML]="'NEW_ACCOUNT.SORRY_COMEBACK_LATER' | translate"></div>
            </div>
          </div>
        </div>
      </div>

      <!-- FORM -->
      <div class="content-column" *ngIf="!registrationFinished && selfRegisterActive">
        <!-- GRAPHIC DOT AND FORM TITLE -->
        <div class="row-wrapper">
          <div nz-row>
            <div
              nz-col
              [nzXs]="labelW.xs"
              [nzSm]="labelW.sm"
              [nzMd]="labelW.md"
              [nzLg]="labelW.lg"
              [nzXl]="labelW.xl"
              [nzXXl]="labelW.xxl"></div>
            <div
              nz-col
              [nzXs]="inputW.xs"
              [nzSm]="inputW.sm"
              [nzMd]="inputW.md"
              [nzLg]="inputW.lg"
              [nzXl]="inputW.xl"
              [nzXXl]="inputW.xxl"></div>
          </div>
          <div nz-row>
            <div
              nz-col
              [nzXs]="labelW.xs"
              [nzSm]="labelW.sm"
              [nzMd]="labelW.md"
              [nzLg]="labelW.lg"
              [nzXl]="labelW.xl"
              [nzXXl]="labelW.xxl"></div>
            <div
              nz-col
              [nzXs]="inputW.xs"
              [nzSm]="inputW.sm"
              [nzMd]="inputW.md"
              [nzLg]="inputW.lg"
              [nzXl]="inputW.xl"
              [nzXXl]="inputW.xxl">
              <div class="form-title">{{ 'NEW_ACCOUNT.REGISTER_MYSELF' | translate }}</div>
            </div>
          </div>
        </div>
        <!-- FORM -->
        <form nz-form [formGroup]="newAccountForm" class="row-wrapper">
          <nz-form-item>
            <nz-form-label
              [nzXs]="labelW.xs"
              [nzSm]="labelW.sm"
              [nzMd]="labelW.md"
              [nzLg]="labelW.lg"
              [nzXl]="labelW.xl"
              [nzXXl]="labelW.xxl"
              nzRequired
              nzFor="name">
              {{ 'NEW_ACCOUNT.NAME' | translate }}
            </nz-form-label>
            <nz-form-control
              [nzXs]="inputW.xs"
              [nzSm]="inputW.sm"
              [nzMd]="inputW.md"
              [nzLg]="inputW.lg"
              [nzXl]="inputW.xl"
              [nzXXl]="inputW.xxl"
              [nzErrorTip]="errorTpl"
              nzHasFeedback>
              <input nz-input id="name" type="text" formControlName="name" (focus)="scrollToInput($event)" required />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label
              [nzXs]="labelW.xs"
              [nzSm]="labelW.sm"
              [nzMd]="labelW.md"
              [nzLg]="labelW.lg"
              [nzXl]="labelW.xl"
              [nzXXl]="labelW.xxl"
              nzRequired
              nzFor="surname">
              {{ 'NEW_ACCOUNT.SURNAME' | translate }}
            </nz-form-label>
            <nz-form-control
              [nzXs]="inputW.xs"
              [nzSm]="inputW.sm"
              [nzMd]="inputW.md"
              [nzLg]="inputW.lg"
              [nzXl]="inputW.xl"
              [nzXXl]="inputW.xxl"
              [nzErrorTip]="errorTpl"
              nzHasFeedback>
              <input
                nz-input
                id="surname"
                type="text"
                formControlName="surname"
                (focus)="scrollToInput($event)"
                required />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label
              [nzXs]="labelW.xs"
              [nzSm]="labelW.sm"
              [nzMd]="labelW.md"
              [nzLg]="labelW.lg"
              [nzXl]="labelW.xl"
              [nzXXl]="labelW.xxl"
              nzRequired
              nzFor="email">
              {{ 'NEW_ACCOUNT.EMAIL' | translate }}
            </nz-form-label>
            <nz-form-control
              [nzXs]="inputW.xs"
              [nzSm]="inputW.sm"
              [nzMd]="inputW.md"
              [nzLg]="inputW.lg"
              [nzXl]="inputW.xl"
              [nzXXl]="inputW.xxl"
              [nzErrorTip]="errorTpl"
              nzHasFeedback>
              <input
                nz-input
                id="email"
                formControlName="email"
                type="email"
                (focus)="scrollToInput($event)"
                required />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label
              [nzXs]="labelW.xs"
              [nzSm]="labelW.sm"
              [nzMd]="labelW.md"
              [nzLg]="labelW.lg"
              [nzXl]="labelW.xl"
              [nzXXl]="labelW.xxl"
              nzRequired
              nzFor="confirmEmail">
              {{ 'NEW_ACCOUNT.CONFIRM_EMAIL' | translate }}
            </nz-form-label>
            <nz-form-control
              [nzXs]="inputW.xs"
              [nzSm]="inputW.sm"
              [nzMd]="inputW.md"
              [nzLg]="inputW.lg"
              [nzXl]="inputW.xl"
              [nzXXl]="inputW.xxl"
              [nzErrorTip]="errorTpl"
              nzHasFeedback>
              <input
                nz-input
                id="confirmEmail"
                type="email"
                autocomplete="false"
                formControlName="confirmEmail"
                (focus)="scrollToInput($event)"
                required />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label
              [nzXs]="labelW.xs"
              [nzSm]="labelW.sm"
              [nzMd]="labelW.md"
              [nzLg]="labelW.lg"
              [nzXl]="labelW.xl"
              [nzXXl]="labelW.xxl"
              nzRequired
              nzFor="mobile">
              {{ 'NEW_ACCOUNT.MOBILE' | translate }}
            </nz-form-label>
            <nz-form-control
              [nzXs]="inputW.xs"
              [nzSm]="inputW.sm"
              [nzMd]="inputW.md"
              [nzLg]="inputW.lg"
              [nzXl]="inputW.xl"
              [nzXXl]="inputW.xxl"
              [nzErrorTip]="errorTpl"
              nzHasFeedback>
              <input
                nz-input
                id="mobile"
                formControlName="telephoneNumber"
                type="text"
                (focus)="scrollToInput($event)"
                required />
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label
              id="GDPRAcceptedAt"
              [nzXs]="labelW.xs"
              [nzSm]="labelW.sm"
              [nzMd]="labelW.md"
              [nzLg]="labelW.lg"
              [nzXl]="labelW.xl"
              [nzXXl]="labelW.xxl"
              [innerHTML]="'NEW_ACCOUNT.GDPR' | translate">
            </nz-form-label>
            <nz-form-control
              [nzXs]="inputW.xs"
              [nzSm]="inputW.sm"
              [nzMd]="inputW.md"
              [nzLg]="inputW.lg"
              [nzXl]="inputW.xl"
              [nzXXl]="inputW.xxl">
              <label nz-checkbox formControlName="hasAcceptedGDPR" required> </label>
            </nz-form-control>
          </nz-form-item>

          <!--          <nz-form-item>-->
          <!--            <nz-form-control [nzSm]="12" [nzXs]="24"></nz-form-control>-->
          <!--            <nz-form-control [nzSm]="12" [nzXs]="24">-->
          <!--              <div class="row-centered">-->
          <!--                <label nz-checkbox formControlName="allowedToAddOwnUserProcesses">-->
          <!--                  {{ 'MANAGEMENT.FOREIGNER_FORM.ALLOWED_TO_CREATE_USER_PROCESS' | translate }}-->
          <!--                </label>-->
          <!--                <mat-icon-->
          <!--                  class="info-suffix-icon"-->
          <!--                  matTooltip="{{ 'MANAGEMENT.FOREIGNER_FORM.ALLOWED_TO_CREATE_USER_PROCESS_EXPLANATION' | translate }}"-->
          <!--                  matTooltipClass="personalDataFormTooltip">-->
          <!--                  info_outline-->
          <!--                </mat-icon>-->
          <!--              </div>-->
          <!--            </nz-form-control>-->
          <!--          </nz-form-item>-->
        </form>
        <!-- SPINNER ONLOADING SERVER_RESPONSE -->
        <div class="row-wrapper" *ngIf="loading$ | async">
          <div nz-row>
            <div
              nz-col
              [nzXs]="labelW.xs"
              [nzSm]="labelW.sm"
              [nzMd]="labelW.md"
              [nzLg]="labelW.lg"
              [nzXl]="labelW.xl"
              [nzXXl]="labelW.xxl"></div>
            <div
              nz-col
              [nzXs]="inputW.xs"
              [nzSm]="inputW.sm"
              [nzMd]="inputW.md"
              [nzLg]="inputW.lg"
              [nzXl]="inputW.xl"
              [nzXXl]="inputW.xxl"
              class="loading-container">
              <mat-spinner [diameter]="45"></mat-spinner>
            </div>
          </div>
        </div>
        <!-- SERVER ERROR MESSAGE -->
        <div class="row-wrapper" *ngIf="newAccountForm.invalid && (errorMessage$ | async) as errorMessage">
          <div nz-row>
            <!-- <div
              nz-col
              [nzXs]="labelW.xs"
              [nzSm]="labelW.sm"
              [nzMd]="labelW.md"
              [nzLg]="labelW.lg"
              [nzXl]="labelW.xl"
              [nzXXl]="labelW.xxl"></div> -->
            <div class="server-error-message" nz-col [nzSpan]="24">
              <p
                [innerHTML]="'NEW_ACCOUNT.SERVER_ERROR.' + errorMessage | translate: { email: lastUsedData.email }"></p>
            </div>
          </div>
        </div>
        <!-- REGISTER BUTTON + I ALREADY HAVE ACCOUNT LINK -->
        <div class="row-wrapper">
          <div nz-row>
            <div
              nz-col
              [nzXs]="labelW.xs"
              [nzSm]="labelW.sm"
              [nzMd]="labelW.md"
              [nzLg]="labelW.lg"
              [nzXl]="labelW.xl"
              [nzXXl]="labelW.xxl"></div>
            <div
              nz-col
              [nzXs]="24"
              [nzSm]="24"
              [nzMd]="inputW.md"
              [nzLg]="inputW.lg"
              [nzXl]="inputW.xl"
              [nzXXl]="inputW.xxl">
              <button
                (click)="sendForm()"
                class="submit-button"
                [disabled]="
                  newAccountForm.pristine ||
                  (newAccountForm.dirty && newAccountForm.invalid) ||
                  (loading$ | async) ||
                  (errorMessage$ | async) === 'SELF_REGISTRATION_CURRENTLY_UNAVAILABLE'
                "
                mat-raised-button
                color="primary">
                {{ 'NEW_ACCOUNT.REGISTER_MYSELF' | translate }}
              </button>
            </div>
          </div>
          <div nz-row class="login-page-link-row">
            <div
              nz-col
              [nzXs]="labelW.xs"
              [nzSm]="labelW.sm"
              [nzMd]="labelW.md"
              [nzLg]="labelW.lg"
              [nzXl]="labelW.xl"
              [nzXXl]="labelW.xxl"></div>
            <div
              nz-col
              [nzXs]="inputW.xs"
              [nzSm]="inputW.sm"
              [nzMd]="inputW.md"
              [nzLg]="inputW.lg"
              [nzXl]="inputW.xl"
              [nzXXl]="inputW.xxl">
              <a (click)="$event.preventDefault()" [routerLink]="['/login']">
                {{ 'NEW_ACCOUNT.I_HAVE_ACCOUNT_LOGIN' | translate }}
              </a>
            </div>
          </div>
        </div>
      </div>

      <!-- REGISTRATION FINISHED MESSAGES -->
      <div class="content-column registration-finished-column" *ngIf="registrationFinished">
        <div class="row-wrapper">
          <div nz-row>
            <div nz-col [nzSpan]="24" class="account-created-text">
              {{ 'NEW_ACCOUNT.ACCOUNT_CREATED' | translate }}
            </div>
          </div>
          <div nz-row>
            <div nz-col [nzSpan]="24">
              <p class="next-steps-explanation" [innerHTML]="'NEW_ACCOUNT.ACTIVATION_EXPLANATION' | translate"></p>
              <p class="close-page-info" [innerHTML]="'NEW_ACCOUNT.YOU_CAN_CLOSE_THIS_PAGE' | translate"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<app-cookie-banner></app-cookie-banner>

<ng-template #userLangLoader>
  <div class="column right">
    <mat-spinner [diameter]="35"></mat-spinner>
  </div>
</ng-template>

<ng-template #errorTpl let-control>
  <ng-container *ngIf="control.hasError('required')">
    {{ 'FORM-VALIDATION.FIELD_IS_REQUIRED' | translate }}
  </ng-container>
  <ng-container *ngIf="control.hasError('pattern')">
    {{ 'PRE_SURVEY.FIELD_INCORRECT' | translate }}
  </ng-container>
  <ng-container *ngIf="control.hasError('email')">
    {{ 'PRE_SURVEY.FIELD_INCORRECT' | translate }}
  </ng-container>
  <ng-container *ngIf="control.hasError('minlength')">
    {{ 'NEW_ACCOUNT.VALUE_TOO_SHORT' | translate }}
  </ng-container>
  <ng-container *ngIf="control.hasError('maxlength')">
    {{ 'NEW_ACCOUNT.VALUE_TOO_LONG' | translate }}
  </ng-container>
  <ng-container *ngIf="control.hasError('confirmEmail')">
    {{ 'NEW_ACCOUNT.CONFIRM_EMAIL_ERROR' | translate }}
  </ng-container>
  <ng-container *ngIf="control.hasError('emailTaken')">
    {{ 'NEW_ACCOUNT.EMAIL_TAKEN_ERROR' | translate }}
  </ng-container>
</ng-template>

<ng-template #ApiNotAvailable>
  <div class="column right">
    <div class="utils-buttons-wrapper">
      <app-utils-buttons (userLangChanged)="userLangChanged($event)"></app-utils-buttons>
    </div>
    <div class="content-column">
      <div class="form-title error-title">
        {{ 'NT.NO_CONNECTION_WITH_SERVER' | translate }}
      </div>
      <div class="connection-error-block">
        <div class="subtitle" [innerHTML]="'NT.CONNECTION_ERROR_EXPLANATION' | translate"></div>
        <ul>
          <li [innerHTML]="'NT.WAIT_WE_WILL_TRY_TO_RECONNECT' | translate"></li>
          <li [innerHTML]="'NT.CHECK_INTERNET_CONNECTION' | translate"></li>
          <li [innerHTML]="'NT.CHECK_FIREWALL_SETTINGS' | translate"></li>
          <li [innerHTML]="'NT.CONSULT_LOCAL_NETWORK_ADMIN' | translate"></li>
          <li [innerHTML]="'NT.COMEBACK_LATER' | translate"></li>
        </ul>
      </div>
    </div>
  </div>
</ng-template>
