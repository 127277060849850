import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserDocumentsManagementState } from './user-documents-management.state';

export const selectUserDocumentsManagementState =
  createFeatureSelector<UserDocumentsManagementState>('userDocumentsManagement');

export const selectLoading = createSelector(selectUserDocumentsManagementState, state => state.loading);

export const selectErrorMessage = createSelector(selectUserDocumentsManagementState, state => state.errorMessage);

export const selectUserDocs = createSelector(selectUserDocumentsManagementState, state => state.userDocs);

export const selectUserDocsAnswers = createSelector(selectUserDocumentsManagementState, state => state.userDocsAnswers);
