export enum PermissionEnum {
  CAN_ADMINISTRATE_SYSTEM = 'CAN_ADMINISTRATE_SYSTEM',

  CAN_LIST_EMPLOYEES = 'CAN_LIST_EMPLOYEES',
  CAN_EDIT_EMPLOYEE = 'CAN_EDIT_EMPLOYEE',
  CAN_ADD_EMPLOYEE = 'CAN_ADD_EMPLOYEE',
  CAN_REMOVE_EMPLOYEE = 'CAN_REMOVE_EMPLOYEE',

  CAN_LIST_FOREIGNERS = 'CAN_LIST_FOREIGNERS',
  CAN_EDIT_FOREIGNER = 'CAN_EDIT_FOREIGNER',
  CAN_ADD_FOREIGNER = 'CAN_ADD_FOREIGNER',
  CAN_REMOVE_FOREIGNER = 'CAN_REMOVE_FOREIGNER',

  CAN_USE_MOBILE = 'CAN_USE_MOBILE',

  CAN_EDIT_OWN_DATA = 'CAN_EDIT_OWN_DATA',
  CAN_ACTIVATE_OWN_ACCOUNT = 'CAN_ACTIVATE_OWN_ACCOUNT',

  CAN_LIST_COMPANIES = 'CAN_LIST_COMPANIES',
  CAN_EDIT_COMPANY = 'CAN_EDIT_COMPANY',
  CAN_ADD_COMPANY = 'CAN_ADD_COMPANY',
  CAN_REMOVE_COMPANY = 'CAN_REMOVE_COMPANY',

  CAN_EDIT_VISIT = 'CAN_EDIT_VISIT',
  CAN_REMOVE_VISIT = 'CAN_REMOVE_VISIT',

  CAN_READ_USER_PROCESS_HISTORY = 'CAN_READ_USER_PROCESS_HISTORY',

  // EMPLOYER
  CAN_LIST_COMPANY_EMPLOYEES = 'CAN_LIST_COMPANY_EMPLOYEES',
  CAN_DISPLAY_COMPANY_EMPLOYEE_APPLICATION_STATUS = 'CAN_DISPLAY_COMPANY_EMPLOYEE_APPLICATION_STATUS',

  CAN_MANAGE_USERS_PARTNER_ACCOUNT = 'CAN_MANAGE_USERS_PARTNER_ACCOUNT',
}

export interface Permission {
  id: string;
  name: string;
  key: PermissionEnum;
}
