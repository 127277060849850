<div class="page-container">
  <!-- LEFT COLUMN -->
  <div class="column left" *ngIf="deviceInfo.deviceTypeDetected === 'DESKTOP'">
    <div class="texts-container">
      <h1 class="main-title-text" [innerHTML]="'LOGIN_PAGE.MAIN_TITLE' | translate: {}"></h1>
      <h3 class="subtitle-text" [innerHTML]="'LOGIN_PAGE.SUBTITLE' | translate: {}"></h3>
    </div>
  </div>

  <!-- RIGHT COLUMN -->
  <ng-container *ngIf="APIAvailable">
    <div class="column right" *ngIf="currentUserLang?.key; else userLangLoader">
      <div class="utils-buttons-wrapper">
        <app-utils-buttons></app-utils-buttons>
      </div>

      <!-- FORM -->
      <div class="content-column">
        <div nz-row>
          <div nz-col [nzSpan]="24">
            <div class="graphic-dot"></div>
          </div>
        </div>
        <div nz-row>
          <div nz-col [nzSpan]="24">
            <div class="form-title">
              {{ 'ACTIVATE_ACCOUNT_PAGE.SET_PASSWORD_AND_ACTIVATE_ACCOUNT' | translate }}
            </div>
          </div>
        </div>
        <form nz-form [formGroup]="form">
          <nz-form-item [class.has-hint]="!form.controls.password.dirty">
            <nz-form-label [nzSpan]="24" nzRequired nzFor="activateAccountPassword">
              {{ 'ACTIVATE_ACCOUNT_PAGE.PASSWORD' | translate }}
            </nz-form-label>
            <nz-form-control [nzSpan]="24" nzErrorTip="{{ 'ACTIVATE_ACCOUNT_PAGE.INVALID_PASSWORD' | translate }}">
              <nz-input-group [nzSuffix]="suffixTemplate">
                <input
                  nz-input
                  id="activateAccountPassword"
                  [type]="isPasswordVisible ? 'text' : 'password'"
                  (focus)="scrollToInput($event)"
                  formControlName="password"
                  placeholder="{{ 'ACTIVATE_ACCOUNT_PAGE.ENTER_PASSWORD' | translate }}"
                  required />
              </nz-input-group>
              <ng-template #suffixTemplate>
                <i
                  nz-icon
                  [nzType]="isPasswordVisible ? 'eye-invisible' : 'eye'"
                  (click)="isPasswordVisible = !isPasswordVisible"></i>
              </ng-template>
              <div class="hint" *ngIf="!form.controls.password.dirty">
                {{ 'ACTIVATE_ACCOUNT_PAGE.PASSWORD_HINT' | translate }}
              </div>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label [nzSpan]="24" nzRequired nzFor="activateAccountPasswordConfirmation">
              {{ 'ACTIVATE_ACCOUNT_PAGE.PASSWORD_CONFIRMATION' | translate }}
            </nz-form-label>
            <nz-form-control
              [nzSpan]="24"
              nzErrorTip="{{ 'ACTIVATE_ACCOUNT_PAGE.INVALID_PASSWORD_CONFIRMATION' | translate }}">
              <nz-input-group [nzSuffix]="suffixConfirmTemplate">
                <input
                  nz-input
                  id="activateAccountPasswordConfirmation"
                  [type]="isPasswordConfirmVisible ? 'text' : 'password'"
                  (focus)="scrollToInput($event)"
                  formControlName="passwordConfirmation"
                  placeholder="{{ 'ACTIVATE_ACCOUNT_PAGE.CONFIRM_PASSWORD' | translate }}"
                  required />
              </nz-input-group>
              <ng-template #suffixConfirmTemplate>
                <i
                  nz-icon
                  [nzType]="isPasswordConfirmVisible ? 'eye-invisible' : 'eye'"
                  (click)="isPasswordConfirmVisible = !isPasswordConfirmVisible"></i>
              </ng-template>
            </nz-form-control>
          </nz-form-item>

          <!-- Pin -->
          <ng-container *ngIf="pinRequired">
            <nz-form-item>
              <nz-form-label [nzSpan]="24" nzRequired nzFor="activateAccountPIN">
                {{ 'ACTIVATE_ACCOUNT_PAGE.PIN' | translate }}
              </nz-form-label>
              <nz-form-control [nzSpan]="24" nzErrorTip="{{ 'ACTIVATE_ACCOUNT_PAGE.WRONG_PIN' | translate }}">
                <input
                  nz-input
                  id="activateAccountPIN"
                  type="password"
                  (focus)="scrollToInput($event)"
                  formControlName="pin"
                  placeholder="PIN"
                  autocomplete="off"
                  required />
              </nz-form-control>
            </nz-form-item>
          </ng-container>

          <!-- Submit button -->
          <div nz-row>
            <div nz-col [nzSpan]="24">
              <button
                (click)="submitForm()"
                class="submit-button"
                id="activateAccountSubmitButton"
                [disabled]="form.pristine || (form.dirty && form.invalid) || (loading$ | async)"
                mat-raised-button
                color="primary">
                {{ 'ACTIVATE_ACCOUNT_PAGE.ACTIVATE_ACCOUNT' | translate }}
              </button>
            </div>
          </div>
          <div class="error-message-container" *ngIf="form.get('passwordConfirmation')?.dirty && form.errors?.noMatch">
            {{ 'ACTIVATE_ACCOUNT_PAGE.PASSWORDS_DO_NOT_MATCH' | translate }}
          </div>
          <div
            *ngIf="errorMessage$ | async as errorMessage"
            [ngSwitch]="errorMessage"
            #errorMessageContainer
            class="error-message-container">
            <div *ngSwitchCase="'ERRORS.USER_IS_ALREADY_ACTIVE'">
              <div
                class="main-error-message"
                [innerHTML]="'ACTIVATE_ACCOUNT_PAGE.ERRORS.USER_IS_ALREADY_ACTIVE' | translate"></div>
              <div class="error-links">
                <a (click)="$event.preventDefault()" [routerLink]="['/login']">
                  {{ 'ACTIVATE_ACCOUNT_PAGE.GO_TO_LOGIN' | translate }}
                </a>
                &nbsp;|&nbsp;
                <a (click)="$event.preventDefault()" [routerLink]="['/reset-password']">
                  {{ 'ACTIVATE_ACCOUNT_PAGE.GO_TO_PASSWORD_RESET' | translate }}
                </a>
              </div>
            </div>
            <div *ngSwitchCase="'ERRORS.WRONG_PIN'">
              <div class="main-error-message" [innerHTML]="'ACTIVATE_ACCOUNT_PAGE.WRONG_PIN' | translate"></div>
            </div>
            <div *ngSwitchCase="'ERRORS.UNAUTHORIZED'">
              <div
                class="main-error-message"
                [innerHTML]="'ACTIVATE_ACCOUNT_PAGE.ERRORS.UNAUTHORIZED' | translate"></div>
              <div class="error-explanations">
                <ul>
                  <li [innerHTML]="'ACTIVATE_ACCOUNT_PAGE.CHECK_EMAIL_WITH_LINK' | translate"></li>
                  <li [innerHTML]="'ACTIVATE_ACCOUNT_PAGE.ACTIVATION_LINK_EXPIRATION' | translate"></li>
                  <li [innerHTML]="'ACTIVATE_ACCOUNT_PAGE.IF_DATE_VALID_THEN_LINK_WRONG' | translate"></li>
                  <li [innerHTML]="'ACTIVATE_ACCOUNT_PAGE.IF_DATE_VALID_THEN_LINK_WRONG_2' | translate"></li>
                  <li [innerHTML]="'ACTIVATE_ACCOUNT_PAGE.CONTACT_US' | translate"></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="loading-container" *ngIf="loading$ | async">
            <mat-spinner [diameter]="35"></mat-spinner>
          </div>
        </form>
      </div>
    </div>
    <ng-template #userLangLoader>
      <div class="column right">
        <mat-spinner [diameter]="40"></mat-spinner>
      </div>
    </ng-template>
  </ng-container>
  <ng-container *ngIf="!APIAvailable">
    <div class="column right extra-space">
      <div class="utils-buttons-wrapper">
        <app-utils-buttons></app-utils-buttons>
      </div>
      <div class="content-column extra-space">
        <div class="form-title error-title">
          {{ 'NT.NO_CONNECTION_WITH_SERVER' | translate }}
        </div>
        <div class="connection-error-block">
          <div class="subtitle" [innerHTML]="'NT.CONNECTION_ERROR_EXPLANATION' | translate"></div>
          <ul>
            <li [innerHTML]="'NT.WAIT_WE_WILL_TRY_TO_RECONNECT' | translate"></li>
            <li [innerHTML]="'NT.CHECK_INTERNET_CONNECTION' | translate"></li>
            <li [innerHTML]="'NT.CHECK_FIREWALL_SETTINGS' | translate"></li>
            <li [innerHTML]="'NT.CONSULT_LOCAL_NETWORK_ADMIN' | translate"></li>
            <li [innerHTML]="'NT.COMEBACK_LATER' | translate"></li>
          </ul>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<app-cookie-banner></app-cookie-banner>
