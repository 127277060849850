import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '@shared';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { ArticleContentComponent } from './article-content/article-content.component';
import { ArticleListItemComponent } from './article-list-item/article-list-item.component';
import { ArticlesListComponent } from './articles-list/articles-list.component';
import { ArticlesComponent } from './articles.component';
import { ArticlesService } from './articles.service';

@NgModule({
  declarations: [ArticlesComponent, ArticlesListComponent, ArticleListItemComponent, ArticleContentComponent],
  imports: [CommonModule, SharedModule, NgScrollbarModule],
  providers: [ArticlesService],
})
export class ArticlesModule {}
