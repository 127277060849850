import { Action, createReducer, on } from '@ngrx/store';
import { UserDocumentsManagementState, initialState } from './user-documents-management.state';
import * as userDocumentsManagementActions from '@state/user-documents-management/user-documents-management.actions';

const reducer = createReducer(
  initialState,

  on(userDocumentsManagementActions.getUserProcessSummaryDocuments, state => ({
    ...state,
    loading: true,
    errorMessage: null,
  })),
  on(userDocumentsManagementActions.getUserProcessSummaryDocumentsSuccess, (state, { userDocs }) => ({
    ...state,
    userDocs,
    loading: false,
    errorMessage: null,
  })),
  on(userDocumentsManagementActions.getUserProcessSummaryDocumentsError, (state, { errorMessage }) => ({
    ...state,
    loading: false,
    errorMessage,
  })),

  on(userDocumentsManagementActions.getAdditionalDocumentsAnswers, state => ({
    ...state,
    loading: true,
    errorMessage: null,
  })),
  on(userDocumentsManagementActions.getAdditionalDocumentsAnswersSuccess, (state, { userDocsAnswers }) => ({
    ...state,
    userDocsAnswers,
    loading: false,
    errorMessage: null,
  })),
  on(userDocumentsManagementActions.getAdditionalDocumentsAnswersError, (state, { errorMessage }) => ({
    ...state,
    loading: false,
    errorMessage,
  }))
);

export function userDocumentsManagementReducer(
  state: UserDocumentsManagementState,
  action: Action
): UserDocumentsManagementState {
  return reducer(state, action);
}
