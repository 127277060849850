import {
  AddNewUserProcessOpts,
  AssignProcessTypeOpts,
  ChangeUserDocStatusOpts,
  ChangeUserProcessStatusOpts,
  ChangeUserProcessTypeOpts,
  DeleteVisitDateOpts,
  DocumentsGenerationStatus,
  EditUserProcess,
  EmailHTMLNotification,
  SearchUserActivityOpts,
  ListedUserProcess,
  PaginatedResponse,
  PersonalDetails,
  Process,
  ProcessDocument,
  ProcessDocumentsList,
  SearchProcessesOfUser,
  SearchUsersProcessesPayload,
  SendToInpolRequestOpts,
  SendToMosRequestOpts,
  SetVisitDateOpts,
  UpdateDocumentEnabled,
  UpdateDocumentsBulk,
  UpdateForeignerDocumentQuestionAnswerPayload,
  UpdateUserProcessServicesOpts,
  User,
  UserAsset,
  UserDocument,
  UserDocumentQuestionAnswer,
  UserProcess,
  UserProcessChange,
  UserProcessChangeType,
  ValidationConfig,
} from '@interfaces';
import { createAction, props } from '@ngrx/store';

export const searchUsersProcesses = createAction(
  '[Management] Search Users Processes',
  props<{ payload: SearchUsersProcessesPayload }>()
);
export const searchUsersProcessesSuccess = createAction(
  '[Management] Search Users Processes Success',
  props<{ processes: PaginatedResponse<ListedUserProcess> }>()
);
export const searchUsersProcessesError = createAction(
  '[Management] Search Users Processes Error',
  props<{ errorMessage: string }>()
);

export const searchProcessesOfUser = createAction(
  '[Management] Search Processes of User',
  props<{ payload: SearchProcessesOfUser }>()
);
export const searchProcessesOfUserSuccess = createAction(
  '[Management] Search Processes of User Success',
  props<{ processesOfUser: PaginatedResponse<ListedUserProcess> }>()
);
export const searchProcessesOfUserError = createAction(
  '[Management] Search Processes of User Error',
  props<{ errorMessage: string }>()
);

export const getUserProcessBasicData = createAction(
  '[Management] Get User Process Basic Data',
  props<{ userProcessId: string }>()
);
export const getUserProcessBasicDataSuccess = createAction(
  '[Management] Get User Process Basic Data Success',
  props<{ userProcessBasicData: Partial<UserProcess> }>()
);
export const getUserProcessBasicDataError = createAction(
  '[Management] Get User Process Basic Data Error',
  props<{ errorMessage: string }>()
);

export const addNewUserProcess = createAction(
  '[Management] Add New User Process',
  props<{ payload: AddNewUserProcessOpts }>()
);
export const addNewUserProcessSuccess = createAction(
  '[Management] Add New User Process Success',
  props<{ newUserProcess: ListedUserProcess }>()
);
export const addNewUserProcessError = createAction(
  '[Management] Add New User Process Error',
  props<{ errorMessage: string }>()
);

export const assignProcessType = createAction(
  '[Management] Assign Process Type to User Process',
  props<{ opts: AssignProcessTypeOpts }>()
);
export const assignProcessTypeSuccess = createAction(
  '[Management] Assign Process Type to User Process Success',
  props<{ userProcess: ListedUserProcess }>()
);
export const assignProcessTypeError = createAction('[Management] Assign Process Type to User Process Error');

export const getUser = createAction('[Management] Get User', props<{ userId: string }>());
export const getUserSuccess = createAction('[Management] Get User Success', props<{ user: User }>());
export const getUserError = createAction('[Management] Get User Error', props<{ errorMessage: string }>());

export const getUserProcess = createAction(
  '[Management] Get User Process',
  props<{ userId: string; userProcessId: string }>()
);
export const getUserProcessSuccess = createAction(
  '[Management] Get User Process Success',
  props<{ userProcess: ListedUserProcess }>()
);
export const getUserProcessError = createAction(
  '[Management] Get User Process Error',
  props<{ errorMessage: string }>()
);

export const updateUserProcessBasicData = createAction(
  '[Management] Update User Process Basic Data',
  props<{ userProcessId: string; payloadWithUserId: EditUserProcess }>()
);
export const updateUserProcessBasicDataSuccess = createAction(
  '[Management] Update User Process Basic Data Success',
  props<{ updatedUserProcess: ListedUserProcess }>()
);
export const updateUserProcessBasicDataError = createAction(
  '[Management] Update User Process Basic Data Error',
  props<{ errorMessage: string }>()
);

export const removeUserProcess = createAction('[Management] Remove User Process', props<{ userProcessId: string }>());
export const removeUserProcessSuccess = createAction(
  '[Management] Remove User Process Success',
  props<{ removedUserProcessId: string }>()
);
export const removeUserProcessError = createAction(
  '[Management] Remove User Process Error',
  props<{ errorMessage: string }>()
);

export const restoreUserProcess = createAction('[Management] Restore User Process', props<{ userProcessId: string }>());
export const restoreUserProcessSuccess = createAction(
  '[Management] Restore User Process Success',
  props<{ updatedUserProcess: ListedUserProcess }>()
);
export const restoreUserProcessError = createAction(
  '[Management] Restore User Process Error',
  props<{ errorMessage: string }>()
);

export const getDetailsValidationConfig = createAction(
  '[Management] Get Personal Details validation config',
  props<{ foreignerId: string; userProcessId: string }>()
);
export const getDetailsValidationConfigSuccess = createAction(
  '[Management] Get Personal Details validation config Success',
  props<{ userProcessId: string; members: ValidationConfig; all: ValidationConfig }>()
);
export const getDetailsValidationConfigError = createAction(
  '[User-Auth] Get Personal Details validation config Error',
  props<{ errorMessage: string }>()
);

export const getUserProcessDocuments = createAction(
  '[Management] Get User Process Documents',
  props<{ userId: string; userProcessId: string }>()
);

export const getUserProcessDocumentsSuccess = createAction(
  '[Management] Get User Process Documents Success',
  props<{ userProcessId: string; processDocuments: ProcessDocumentsList }>()
);

export const getUserProcessDocumentsError = createAction(
  '[Management] Get User Process Documents Error',
  props<{ errorMessage: string }>()
);

export const updatePersonalDetails = createAction(
  '[Management] Update Personal Details',
  props<{ userId: string; userProcessId: string; personalDetails: PersonalDetails }>()
);
export const updatePersonalDetailsSuccess = createAction(
  '[Management] Update Personal Details success',
  props<{ userId: string; updatedUserProcess: ListedUserProcess }>()
);
export const updatePersonalDetailsError = createAction(
  '[Management] Update Personal Details Error',
  props<{ errorMessage: string }>()
);

export const setPersonalDetailsVerification = createAction(
  '[Management] Set Personal Details verification',
  props<{ userId: string; userProcessId: string; verified: boolean }>()
);
export const setPersonalDetailsVerificationSuccess = createAction(
  '[Management] Set Personal Details Verification Success',
  props<{ updatedUserProcess: ListedUserProcess }>()
);
export const setPersonalDetailsVerificationError = createAction(
  '[Management] Set Personal Details Verification Error',
  props<{ errorMessage: string }>()
);

export const changeProcessType = createAction(
  '[Management] Change user process type',
  props<ChangeUserProcessTypeOpts>()
);
export const changeProcessTypeSuccess = createAction(
  '[Management] Change user process type Success',
  props<{ updatedUserProcess: ListedUserProcess }>()
);
export const changeProcessTypeError = createAction(
  '[Management] Change user process type Error',
  props<{ errorMessage: string }>()
);

export const changeProcessStatus = createAction(
  '[Management] Change user process status',
  props<ChangeUserProcessStatusOpts>()
);
export const changeProcessStatusSuccess = createAction(
  '[Management] Change user process status Success',
  props<{ updatedUserProcess: ListedUserProcess }>()
);
export const changeProcessStatusError = createAction(
  '[Management] Change user process status Error',
  props<{ errorMessage: string }>()
);

export const setUserDocumentStatus = createAction(
  '[Management] Set user document status',
  props<ChangeUserDocStatusOpts>()
);
export const setUserDocumentStatusSuccess = createAction(
  '[Management] Set user document status Success',
  props<{ updatedUserDocument: UserDocument }>()
);
export const setUserDocumentStatusError = createAction(
  '[Management] Set user document Error',
  props<{ errorMessage: string }>()
);

export const getDocumentsGenerationStatus = createAction(
  '[Management] Get user documents generation status',
  props<{ userId: string; userProcessId: string }>()
);

export const getDocumentsGenerationStatusSuccess = createAction(
  '[Management] Get user documents generation status Success',
  props<{ userProcessId: string; status: DocumentsGenerationStatus }>()
);

export const getDocumentsGenerationStatusError = createAction(
  '[Management] Get user documents generation status Error',
  props<{ errorMessage: string }>()
);

// results always in dispatching getDocumentsGenerationStatus
// or showing error on error
export const generateDocumentsNow = createAction(
  '[Management] Generate documents now',
  props<{ userId: string; userProcessId: string }>()
);

export const generateDocumentsNowError = createAction(
  '[Management] Generate documents now Error',
  props<{ error: any }>()
);

export const printAllDocumentsOfForeinger = createAction(
  '[Management] Print All Documents of Foreigner',
  props<{ userId: string; userProcessId: string }>()
);

export const printAllDocumentsOfForeingerSuccess = createAction(
  '[Management] Print All Documents of Foreigner Success'
);

export const printAllDocumentsOfForeingerError = createAction('[Management] Print All Documents of Foreigner Error');

export const sendDataToInpol = createAction(
  '[Management] Send Data of Foreigner to Inpol',
  props<{ opts: SendToInpolRequestOpts }>()
);

export const sendDataToInpolSuccess = createAction('[Management] Send Data of Foreigner to Inpol Success');

export const sendDataToInpolError = createAction(
  '[Management] Send Data of Foreigner to Inpol Error',
  props<{ errorMessage: string }>()
);

export const sendDataToMos = createAction(
  '[Management] Send Data of Foreigner to MOS',
  props<{ opts: SendToMosRequestOpts }>()
);

export const sendDataToMosSuccess = createAction('[Management] Send Data of Foreigner to MOS Success');

export const sendDataToMosError = createAction(
  '[Management] Send Data of Foreigner to MOS Error',
  props<{ errorMessage: string }>()
);

export const getUserProcessChanges = createAction(
  '[Management] Get User Process Changes History',
  props<{ userProcessId: string; changeTypes?: UserProcessChangeType[]; changeAuthorId?: string }>()
);

export const getUserProcessChangesSuccess = createAction(
  '[Management] Get User Process Changes History Success',
  props<{ userProcessId: string; userProcessChanges: UserProcessChange[] }>()
);

export const getUserProcessChangesError = createAction(
  '[Management] Get User Process Changes History Error',
  props<{ userProcessId: string; errorMessage: string }>()
);

export const getUserActivity = createAction(
  '[Management] Get User Activity',
  props<{ payload: SearchUserActivityOpts }>()
);

export const getUserActivitySuccess = createAction(
  '[Management] Get User Activity Success',
  props<{ userActivity: PaginatedResponse<UserProcessChange> }>()
);

export const getUserActivityError = createAction(
  '[Management] Get User Activity Error',
  props<{ errorMessage: string }>()
);

export const downloadErrorScreenshot = createAction(
  '[Management] Download INPOL Error screenshot',
  props<{ screenshotPath: string }>()
);

export const downloadErrorScreenshotSuccess = createAction('[Management] Download INPOL Error screenshot SUCCESS');

export const downloadErrorScreenshotError = createAction('[Management] Download INPOL Error screenshot ERROR');
export const downloadINPOLRecording = createAction(
  '[Management] Download INPOL Recording',
  props<{ recordingPath: string }>()
);
export const downloadINPOLRecordingSuccess = createAction('[Management] Download INPOL Recording Success');
export const downloadINPOLRecordingError = createAction('[Management] Download INPOL Recording Error');

export const setVisitDate = createAction('[Management] Set Office Visit Date', props<SetVisitDateOpts>());

export const setVisitDateSuccess = createAction(
  '[Management] Set Office Visit Date Success',
  props<{ updatedUserProcess: ListedUserProcess }>()
);

export const setVisitDateError = createAction(
  '[Management] Set Office Visit Date Error',
  props<{ errorMessage: any }>()
);

export const deleteVisitDate = createAction('[Management] Delete Office Visit Date', props<DeleteVisitDateOpts>());
export const deleteVisitDateSuccess = createAction(
  '[Management] Delete Office Visit Date Success',
  props<{ updatedUserProcess: ListedUserProcess }>()
);
export const deleteVisitDateError = createAction(
  '[Management] Delete Office Visit Date Error',
  props<{ errorMessage: any }>()
);

export const confirmVoivoChange = createAction(
  '[Management] Confirm changing voivodeship of user',
  props<{ userId: string; userProcessId: string }>()
);
export const confirmVoivoChangeSuccess = createAction(
  '[Management] Confirm changing voivodeship of user Success',
  props<{ userId: string; userProcessId: string }>()
);
export const confirmVoivoChangeError = createAction(
  '[Management] Confirm changing voivodeship of user Error',
  props<{ userId: string; userProcessId: string; errorMessage: any }>()
);

export const updateDocEnabled = createAction(
  '[Management] Update doc enabled',
  props<{ opts: UpdateDocumentEnabled }>()
);
export const updateDocEnabledBulk = createAction(
  '[Management] Update doc enabled bulk',
  props<{ opts: UpdateDocumentsBulk }>()
);
export const updateDocEnabledSuccess = createAction(
  '[Management] Update doc enabled SUCCESS',
  props<{ updatedDoc: ProcessDocument }>()
);
export const updateDocEnabledBulkSuccess = createAction(
  '[Management] Update doc enabled bulk SUCCESSS',
  props<{ updatedDocs: ProcessDocument[] }>()
);
export const updateDocEnabledError = createAction(
  '[Management] Update doc enabled ERROR',
  props<{ errorMessage: string }>()
);

export const areDocsGenerated = createAction(
  '[Management] Are Docs Generated',
  props<{ userId: string; userProcessId: string }>()
);
export const areDocsGeneratedSuccess = createAction(
  '[Management] Are Docs Generated Success',
  props<{ generated: boolean }>()
);
export const areDocsGeneratedError = createAction(
  '[Management] Are Docs Generated Error',
  props<{ errorMessage: string }>()
);

export const getMergedFileData = createAction(
  '[Management] Get Merged Filed Data',
  props<{ userId: string; userProcessId: string }>()
);

export const getMergedFileDataSuccess = createAction(
  '[Management] Get Merged Filed Data Success',
  props<{ fileLink: string }>()
);

export const getMergedFileDataError = createAction(
  '[Management] Get Merged Filed Data Error',
  props<{ errorMessage: string }>()
);

export const updateForeignerDocumentQuestionAnswer = createAction(
  '[Management] Update Foreigner Document Question Answer',
  props<{ opts: UpdateForeignerDocumentQuestionAnswerPayload }>()
);

export const updateForeignerDocumentQuestionAnswerSuccess = createAction(
  '[Management] Update Foreigner Document Question Answer Success',
  props<{ userProcessId: string; updatedAnswer: UserDocumentQuestionAnswer }>()
);

export const updateForeignerDocumentQuestionAnswerError = createAction(
  '[Management] Update Foreigner Document Question Answer Error',
  props<{ errorMessage: string }>()
);

export const getItemsSentForFinalReview = createAction(
  '[Management] Get Items sent for final review',
  props<{ userProcessId: string }>()
);
export const getItemsSentForFinalReviewSuccess = createAction(
  '[Management] Get Items sent for final review Success',
  props<{ userAssets: UserAsset[] }>()
);
export const getItemsSentForFinalReviewError = createAction(
  '[Management] Get Items sent for final review Error',
  props<{ errorMessage: any }>()
);

export const downloadItemSentForFinalReview = createAction(
  '[Management] Download Item sent for final review',
  props<{ opts: { userProcessId: string; userAssetId: string } }>()
);
export const downloadItemSentForFinalReviewSuccess = createAction(
  '[Management] Download Item sent for final review Success'
);
export const downloadItemSentForFinalReviewError = createAction(
  '[Management] Download Item sent for final review Error'
);

export const updateDocumentsReviewComment = createAction(
  '[Management] Update documents review comment',
  props<{ opts: { userProcessId: string; userId: string; comment: string } }>()
);
export const updateDocumentsReviewCommentSuccess = createAction(
  '[Management] Update documents review comment Success',
  props<{ userProcess: ListedUserProcess }>()
);
export const updateDocumentsReviewCommentError = createAction(
  '[Management] Update documents review comment Error',
  props<{ errorMessage: any }>()
);

export const getProcessWithDocs = createAction(
  '[Management] Get Process Data with all documents',
  props<{ processId: string }>()
);
export const getProcessWithDocsSuccess = createAction(
  '[Management] Get Process Data with all documents Success',
  props<{ process: Process }>()
);
export const getProcessWithDocsError = createAction(
  '[Management] Get Process Data with all documents Error',
  props<{ errorMessage: any }>()
);

export const updateUserProcessServices = createAction(
  '[Management] Update User Process Services',
  props<{ opts: UpdateUserProcessServicesOpts }>()
);
export const updateUserProcessServicesSuccess = createAction(
  '[Management] Update User Process Services Success',
  props<{ updatedUserProcess: ListedUserProcess }>()
);
export const updateUserProcessServicesError = createAction(
  '[Management] Update User Process Services Error',
  props<{ errorMessage: any }>()
);

export const isAnyProcessOfMineMissingAssistant = createAction(
  '[Management] Is any process of my foreigners missing assistant'
);
export const isAnyProcessOfMineMissingAssistantSuccess = createAction(
  '[Management] Is any process of my foreigners missing assistant Success',
  props<{ anyMissing: boolean }>()
);
export const isAnyProcessOfMineMissingAssistantError = createAction(
  '[Management] Is any process of my foreigners missing assistant Error'
);

export const sendFollowUpEmail = createAction(
  '[Management] Send follow-up E-mail',
  props<{ usersId: string[]; messages: EmailHTMLNotification }>()
);
export const sendFollowUpEmailSuccess = createAction('[Management] Send follow-up E-mail Success');
export const sendFollowUpEmailError = createAction(
  '[Management] Send follow-up E-mail Error',
  props<{ errorMessage: any }>()
);
