import { UserDocument } from '@interfaces';
import { createAction, props } from '@ngrx/store';

export const getUserProcessSummaryDocuments = createAction(
  '[User Documents Management] Get User Process Summary Documents',
  props<{ userProcessId: string }>()
);

export const getUserProcessSummaryDocumentsSuccess = createAction(
  '[[User Documents Management] Get User Process Summary Documents Success',
  props<{ userDocs: UserDocument[] }>()
);

export const getUserProcessSummaryDocumentsError = createAction(
  '[User Documents Management] Get User Process Summary Documents Error',
  props<{ errorMessage: string }>()
);

export const getAdditionalDocumentsAnswers = createAction(
  '[User Documents Management] Get Additional Documents Answers',
  props<{ userProcessId: string; userDocsIds: string[] }>()
);

export const getAdditionalDocumentsAnswersSuccess = createAction(
  '[User Documents Management] Get Additional Documents Answers Success',
  props<{ userDocsAnswers: Record<string, string> }>()
);

export const getAdditionalDocumentsAnswersError = createAction(
  '[User Documents Management] Get Additional Documents Answers Error',
  props<{ errorMessage: any }>()
);
