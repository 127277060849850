import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@environment';
import { UserDocument } from '@interfaces';

@Injectable({
  providedIn: 'root',
})
export class UserDocumentsManagementHttpService {
  private API_URL = environment.API_URL;

  constructor(private http: HttpClient) {}

  public getUserProcessSummaryDocuments(payload: { userProcessId: string }): Observable<UserDocument[]> {
    const url = `${this.API_URL}/user-documents-management/user-process/${payload.userProcessId}/summary`;
    return this.http.get<UserDocument[]>(url);
  }

  public getAdditionalDocumentsAnswers(payload: {
    userProcessId: string;
    userDocsIds: string[];
  }): Observable<Record<string, string>> {
    const url = `${this.API_URL}/user-documents-management/user-process/${payload.userProcessId}/get-additional-documents-names`;
    return this.http.post<Record<string, string>>(url, payload.userDocsIds);
  }
}
