import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AvailableLanguages } from '@constants';
import { AnalyticsService } from '@core/services/analytics.service';
import { Article, ArticleContent } from '@interfaces';
import { TranslateService } from '@ngx-translate/core';
import { ReplaySubject, takeUntil } from 'rxjs';
import { ArticlesService, STEP_OF_MODAL } from '../articles.service';

@Component({
  selector: 'app-article-list-item',
  templateUrl: './article-list-item.component.html',
  styleUrls: ['./article-list-item.component.scss'],
})
export class ArticleListItemComponent implements OnInit, OnDestroy {
  private readonly destroy$: ReplaySubject<boolean> = new ReplaySubject(1);

  @Input() article: Article;

  public availableContents: ArticleContent[];

  public currLangKey: AvailableLanguages;
  public showBack = false;
  public increaseHeight = false;
  public bringToTop = false;
  public setHeightTo: number = null;

  constructor(
    private readonly translateService: TranslateService,
    private readonly articlesSerivce: ArticlesService,
    private readonly analyticsService: AnalyticsService
  ) {}

  public ngOnInit(): void {
    const currLang = this.translateService.currentLang;
    this.availableContents = [];

    this.currLangKey = currLang.toLowerCase() as AvailableLanguages;
    const voivoComparator = this.article.hasVoivodeshipIsolation
      ? (v: string) => v !== null
      : (v: string) => v === null;

    const avContents = this.article.contents.filter(({ voivodeshipId, language: { key: langKey } }) => {
      return voivoComparator(voivodeshipId) && langKey.toLowerCase() === this.currLangKey;
    });

    if (avContents.length > 1) {
      avContents.sort((a, b) => a.voivodeship?.name.localeCompare(b.voivodeship?.name));
    }

    this.availableContents = avContents;

    this.articlesSerivce.showBackSideOf$
      .asObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe(articleId => {
        const currCardClicked = articleId === this.article.id;
        // if this not current card -> make sure that it's reverted
        if (!currCardClicked) {
          this.increaseHeight = false;
          this.setHeightTo = null;
          setTimeout(() => {
            this.bringToTop = false;
            this.showBack = false;
          }, 300);
        }

        if (currCardClicked) {
          if (this.showBack) {
            this.increaseHeight = false;
            this.setHeightTo = null;
            setTimeout(() => {
              this.bringToTop = false;
              this.showBack = false;
            }, 500);
          } else {
            this.articlesSerivce.scrollToElem$.next(`div.article-${this.article.id}`);
            this.showBack = true;
            this.bringToTop = true;
            setTimeout(() => {
              this.setHeightTo = this.availableContents?.length ? 200 + this.availableContents.length * 25 : 300;
              this.increaseHeight = true;
            }, 300);
          }
        }
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public voivoSelected(content: ArticleContent): void {
    this.goToArticleContent(content);
  }

  public cardClicked() {
    this.analyticsService.trackEvent('user_event', 'user_clicked_article_card', {
      articleTitle: this.article.title.en,
    });
    // if no voivo isolation - just go to the article immediately
    if (!this.article?.hasVoivodeshipIsolation) {
      this.goToArticleContent(this.availableContents[0]);
      return;
    }

    this.articlesSerivce.showBackSideOf$.next(this.article.id);
  }

  public goToArticleContent(content: ArticleContent): void {
    this.analyticsService.trackEvent('user_event', 'user_selected_article', {
      articleTitle: this.article.title.en,
    });
    this.articlesSerivce.selectedArticleContent$.next({
      article: this.article,
      content,
    });
    this.articlesSerivce.currStepOfModal$.next(STEP_OF_MODAL.CONTENT);
  }
}
