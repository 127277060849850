import { AfterViewInit, Component, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AnalyticsService } from '@core/services/analytics.service';
import { DeviceInfoService } from '@core/services/device-info.service';
import { LocalStorageService } from '@core/services/local-storage.service';
import { AllDeviceInfo, DeviceType, UserProcess } from '@interfaces';
import { MenuItem } from '@layout/side-menu.config';
import { debounce } from 'lodash-es';
import { NzTooltipBaseDirective } from 'ng-zorro-antd/tooltip';
import { ReplaySubject, take, takeUntil } from 'rxjs';
import { ArticlesComponent } from 'src/app/articles/articles.component';
import { ArticlesService } from 'src/app/articles/articles.service';

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss'],
})
export class SidebarMenuComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() sideMenuItems: MenuItem[];
  @Input() isForeigner: boolean;
  @Input() myProcess: UserProcess;
  @Input() addingNewProcess: null | { id: string } = null;

  @ViewChild('articleHintTooltipWrap') articleHintTooltipWrap: NzTooltipBaseDirective;

  @HostListener('scroll', ['$event']) onScroll(): void {
    this.debounceUpdatedArticleHintPosition();
  }
  private destroy$: ReplaySubject<boolean> = new ReplaySubject(1);

  public deviceInfo: AllDeviceInfo;
  public articlesModalRef: MatDialogRef<ArticlesComponent>;
  public seenArticlesHint: boolean;
  public initInProgress = true; // Workaround for bad position of hint window

  private debounceUpdatedArticleHintPosition = debounce(() => {
    this.articleHintTooltipWrap?.updatePosition();
  }, 10);

  constructor(
    private readonly deviceInfoService: DeviceInfoService,
    private readonly articlesService: ArticlesService,
    private readonly lsService: LocalStorageService,
    private readonly analyticsService: AnalyticsService
  ) {}

  ngOnInit() {
    this.initInProgress = true;
    this.deviceInfo = this.deviceInfoService.getInfo();
    this.deviceInfoService.infoEmitter.pipe(takeUntil(this.destroy$)).subscribe(info => {
      this.deviceInfo = info;
    });
  }

  ngAfterViewInit(): void {
    // Workaround for bad position of hint window
    const timeout = this.deviceInfo?.deviceTypeDetected === DeviceType.DESKTOP ? 800 : 50;
    setTimeout(() => {
      this.seenArticlesHint = this.lsService.getArticlesHintSeen();
      this.initInProgress = false;
    }, timeout);
  }

  public openArticlesModal(): void {
    if (!this.seenArticlesHint) {
      this.closeArticlesHint('OPEN_ARTICLES_MODAL');
    }

    this.articlesModalRef = this.articlesService.open();
    this.articlesModalRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        this.articlesModalRef = null;
      });
  }

  public closeArticlesHint(SOURCE: 'X_BUTTON' | 'OPEN_ARTICLES_MODAL'): void {
    if (SOURCE === 'X_BUTTON') {
      this.analyticsService.trackEvent('user_event', 'user_closed_articles_hint');
    }

    this.seenArticlesHint = true;
    this.lsService.setArticlesHintSeen(true);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
