import { Location } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ReplaySubject, take, takeUntil } from 'rxjs';
import { ArticlesService, STEP_OF_MODAL } from './articles.service';

@Component({
  selector: 'app-articles-main-component',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.scss'],
})
export class ArticlesComponent implements OnInit, OnDestroy {
  private readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  @ViewChild('dialogContent') dialogContentElem: ElementRef;

  public currStepOfModal$ = this.articlesService.currStepOfModal$.asObservable();
  public STEP_OF_MODAL = STEP_OF_MODAL;
  constructor(
    private readonly articlesService: ArticlesService,
    private readonly router: Router,
    private readonly _location: Location
  ) {}

  ngOnInit(): void {
    this.articlesService.scrollToElem$
      .asObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe(elemSelector => {
        const htmlElem = this.dialogContentElem.nativeElement as HTMLElement;
        const elemToScrollTo = htmlElem.querySelector(elemSelector);
        if (elemToScrollTo?.scrollIntoView) {
          elemToScrollTo.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  close(): void {
    this.articlesService.close();
  }

  public goBack(): void {
    this.currStepOfModal$.pipe(take(1)).subscribe(currStep => {
      if (currStep === STEP_OF_MODAL.ARTICLES_LIST) {
        this.close();
        return;
      }

      if (currStep === STEP_OF_MODAL.CONTENT) {
        this.articlesService.currStepOfModal$.next(STEP_OF_MODAL.ARTICLES_LIST);
        this.articlesService.selectedArticleContent$.next({
          article: null,
          content: null,
        });
        return;
      }
    });
  }
}
