import {
  Annex,
  JobGenerationOpts,
  Pagination,
  PersonalDetails,
  PreSurvey,
  Process,
  Purchase,
  User,
  UserDocument,
  VisitAssistant,
  VoivodeshipOffice,
} from '@interfaces';
import { Moment } from 'moment';

export enum UserProcessStatus {
  // --- NO TYPE ASSIGNED - Foreigner fills pre-survey
  FILLING_PRE_SURVEY = 'FILLING_PRE_SURVEY',

  // --- NO TYPE ASSIGNED - Pre-survey is in verification
  PRE_SURVEY_VERIFICATION = 'PRE_SURVEY_VERIFICATION',

  // --- STANDARD | SEMI_POST | UKR
  // We know which process should be assigned to foreigner situation
  // Foreigner should fill all required details
  // processId = set
  FILLING_DETAILS = 'FILLING_DETAILS',

  // --- STANDARD | SEMI_POST | UKR
  // Foreigner filled required info
  // We now know everything(voivoId procId) requried to fetch list of documents
  // userProcess.processId = set
  // personalDetails.voivodeshipDetailsId = set
  COMPLETING_DOCUMENTS = 'COMPLETING_DOCUMENTS',

  // --- STANDARD | SEMI_POST | UKR
  // Foreigner finished completion of required documents and everything is ready to be reviewed
  // by the employee
  READY_FOR_REVIEW = 'READY_FOR_REVIEW',

  // --- STANDARD | SEMI_POST | UKR
  // Employee reviewed all of the documents and found issues which has to be changed by the foreigner
  CHANGES_REQUESTED = 'CHANGES_REQUESTED',

  // --- STANDARD | SEMI_POST | UKR
  // Employee approved whole process
  // PDF generation starting now
  // Employee & Foreigner sees that documents are beeing generated
  // Foreigner get notification when everythign ready and process becomes `REVIEWED_READY_TO_PRINT`
  APPROVED = 'APPROVED',

  // --- STANDARD
  // All of documents generated & ready to print
  // Foreigner gets notification that he can login and:
  // -- provide his availability for VOIVODESHIP office visit
  // -- print every document
  REVIEWED_READY_TO_PRINT = 'REVIEWED_READY_TO_PRINT',

  // --- SEMI_POST | UKR
  // All of documents generated & ready to print
  // Foreigner gets notification that he can login and:
  // -- provide his availability for COMPANY office visit (for signing the documents)
  // --- OR select option to send signed documents via POST
  // -- print every document
  REVIEWED_READY_TO_PRINT_WAITING_FOR_DOCUMENTS_SIGNATURE = 'REVIEWED_READY_TO_PRINT_WAITING_FOR_DOCUMENTS_SIGNATURE',

  // --- STANDARD
  // Foreigner provided dates of his availability for VOIVOIDESHIP office visit
  // Foreigner gets notification that we're in the process of scheduling VOIVODESHIP office visit
  OFFICE_VISIT_AVAILABILITY_PROVIDED = 'OFFICE_VISIT_AVAILABILITY_PROVIDED',

  // --- SEMI_POST
  // Foreigner provided signed documents (came to office or sent via post) & application was sent to the voivodeship office
  // Awaiting for office visit date for fingerprints submition
  DOCUMENTS_SIGNED_WAITING_FOR_VOIVO_OFFICE_VISIT_DATE = 'DOCUMENTS_SIGNED_WAITING_FOR_VOIVO_OFFICE_VISIT_DATE',

  // --- STANDARD | SEMI_POST
  // Employee scheduled office visit and entered date of visit into our system
  // Foreigner gets notification about scheduled visit and instructions to print everything and go to the office
  OFFICE_VISIT_SCHEDULED = 'OFFICE_VISIT_SCHEDULED',

  // --- STANDARD | SEMI_POST | UKR
  // Application submited, awaiting decision
  APPLICATION_SUBMITTED = 'APPLICATION_SUBMITTED',

  // --- STANDARD | SEMI_POST | UKR
  // DECISION ISSUED
  DECISION_ISSUED = 'DECISION_ISSUED',

  // --- STANDARD | SEMI_POST | UKR
  // Process has been finished or abandoned sometime ago & user should not have access to it anymore
  ARCHIVED = 'ARCHIVED',
}

export interface UserProcess {
  id: string;
  name: string | null;
  status: UserProcessStatus;
  mode: UserProcessMode;
  purposeComment: string | null;
  hasFullHandling: boolean;
  hasAssistantService: boolean;

  userId: string;
  user: User;

  personalDetailsId: string;
  personalDetails: PersonalDetails;
  preSurveyId: string | null;
  preSurvey: PreSurvey | null;
  annex: Annex[];

  processId: string | null;
  process: Process | null;
  purchaseId?: string | null;
  purchase: Purchase | null;

  documentsSigningMode: DocumentsSigningMode | null;
  companyOfficePlannedVisitDate: Date | null;

  availabilityForOfficeVisit?: AvailabilityForOfficeVisit | null;
  immigrationOfficeVisitDate: Date | null;
  officeVisitReservationNumber: string | null;
  visitAssistant: VisitAssistant;
  confirmedReadinessForVisitAt: Date | null;

  documentsReviewComment: string | null;

  selectedVoivodeshipOfficeId: string | null;
  selectedVoivodeshipOffice?: VoivodeshipOffice | null;

  updatedByUserId: string; // userID
  updatedByUser?: Partial<User>; // userID

  userDocuments?: UserDocument[];
  usingCustomInstructionItemsList: boolean;
  showFeesOnInstructionItemsList: boolean;

  createdAt: Date | string;
  updatedAt: Date | string;
}

export interface AddNewUserProcessOpts {
  userId: string;
  processId?: string;
  name?: string;
  mode?: UserProcessMode;
  purposeComment?: string;
  hasAssistantService: boolean;
  hasFullHandling: boolean;
}

export interface EditUserProcess {
  userId: string;
  processId?: string;
  name?: string;
  purposeComment?: string;
  mode?: UserProcessMode;
  hasAssistantService?: boolean;
  hasFullHandling?: boolean;
  selectedVoivodeshipOfficeId?: string;
}

export interface SearchProcessesOfUser extends Pagination {
  userId: string;
  status?: UserProcessStatus;
  name?: string;
  showDeleted?: boolean;
}

export interface ListedUserProcess extends UserProcess {
  updatedByUser: User;
  hasNewMessages: boolean;
}

export interface ChangeUserProcessTypeOpts {
  userId: string;
  userProcessId: string;
  newUserProcessId: string;
}

export interface ChangeUserProcessStatusOpts {
  userId: string;
  userProcessId: string;
  newUserProcessStatus: UserProcessStatus;
}

export enum UserProcessChangeType {
  'USER_PROCESS_CREATED' = 'USER_PROCESS_CREATED', // Not used
  'USER_PROCESS_CHANGED' = 'USER_PROCESS_CHANGED', // Not used
  'USER_PROCESS_TYPE_CHANGED' = 'USER_PROCESS_TYPE_CHANGED',
  'USER_PROCESS_STATUS_CHANGED' = 'USER_PROCESS_STATUS_CHANGED',
  'USER_UPLOADED_DOCUMENT' = 'USER_UPLOADED_DOCUMENT', // Not used
  'USER_REMOVED_UPLOADED_DOCUMENT' = 'USER_REMOVED_UPLOADED_DOCUMENT', // Not used
  'SENDING_TO_INPOL_SUCCESS' = 'SENDING_TO_INPOL_SUCCESS',
  'SENDING_TO_INPOL_ERROR' = 'SENDING_TO_INPOL_ERROR',

  'FOREIGNER_SET_APPOINTMENT_DATE' = 'FOREIGNER_SET_APPOINTMENT_DATE',
  'FOREIGNER_UPDATED_APPOINTMENT_DATE' = 'FOREIGNER_UPDATED_APPOINTMENT_DATE',
  'VISIT_DATE_SCHEDULED' = 'VISIT_DATE_SCHEDULED',
  'VISIT_DATA_UPDATED' = 'VISIT_DATA_UPDATED',
  'VISIT_DATE_REMOVED' = 'VISIT_DATE_REMOVED',
  'VISIT_ASSISTANT_CHANGED' = 'VISIT_ASSISTANT_CHANGED',
  'REQUIRED_DOCUMENTS_LIST_CHANGED' = 'REQUIRED_DOCUMENTS_LIST_CHANGED',

  'CHANGED_INCLUDED_SERVICES_IN_PROCESS' = 'CHANGED_INCLUDED_SERVICES_IN_PROCESS',
  'SENDING_TO_MOS_SUCCESS' = 'SENDING_TO_MOS_SUCCESS',
  'SENDING_TO_MOS_ERROR' = 'SENDING_TO_MOS_ERROR',

  // Missing (17.03.2025)
  'REMOVE_USER_PROCESS' = 'REMOVE_USER_PROCESS',
  'CREATED_NEW_USER_PROCESS' = 'CREATED_NEW_USER_PROCESS',
}

export interface UserProcessChange {
  id: string;
  type: UserProcessChangeType;
  createdAt: Date;
  changeAuthor: User;
  changeAuthorId: string;
  userProcessId: string;
  userProcess?: UserProcess;
  details?: {
    opts?: JobGenerationOpts;
    visitDate?: Date;
    reason?: string;
    newStatus?: string;
    finishedAt?: Date;
    recordingPath?: string;
    exportDate?: string;
    MOSDocumentId?: string;
    userAssetsFileName?: string;
    userAssetsFileFullPath?: string;
    pdfFilePath?: string;
    error?: {
      name?: string;
      screenshotPath?: string;
      recordingPath?: string;
    };
  };
}

export interface SetVisitDateOpts {
  userId: string;
  userProcessId: string;
  immigrationOfficeVisitDate: string;
  selectedVoivodeshipOfficeId: string;
  assistantId: string | null;
  officeVisitReservationNumber: string | null;
  notifyForeigner: boolean;
  updateStatus: boolean;
}

export interface DeleteVisitDateOpts {
  userId: string;
  userProcessId: string;
  visitDate: string;
}

export interface CreateMyProcesOpts {
  // mode: UserProcessMode;
  processId: string;
  comment?: string;
}

export enum AvailabilityMode {
  'DATE_RANGE' = 'DATE_RANGE',
  'PARTICULAR_DATES' = 'PARTICULAR_DATES',
  'ASAP' = 'ASAP',
}

export interface AvailabilityForOfficeVisit {
  mode: AvailabilityMode;
  // array of dates strings
  dates: string[];
}

export enum UserProcessMode {
  /**
   * Normal mode with standard visit in the voivo office for application submition
   */
  STANDARD = 'STANDARD',

  /**
   * Mode in which application submition is sent by post BUT foreigner has to visit office for fingerprints submition
   * In this mode foreigner might send the documents to the office via post OR come to the office to sign the required documents personally
   * Some voivodeships accept only this mode as it is impossible to submit application personally in the voivodeship office
   */
  SEMI_POST = 'SEMI_POST',

  /**
   * AKA FULL_POST
   * this is for UKR citizens that flee war and already has submitted fingerprints.
   * application is submitted via post and foreigner might come to the office to sign the documents BUT
   * doesn't have to go to the voivodeship office
   */
  UKR = 'UKR',
}

export const UserProcessModes = [UserProcessMode.STANDARD, UserProcessMode.SEMI_POST, UserProcessMode.UKR];

export interface VoivoVisitAvailabilityOpts {
  mode: AvailabilityMode;
  startDate?: Moment;
  endDate?: Moment;
  particularDates?: Moment[];
}

export enum DocumentsSigningMode {
  PERSONALLY_IN_OFFICE = 'PERSONALLY_IN_OFFICE',
  SENDING_BY_POST = 'SENDING_BY_POST',
}

export interface SaveDocumentsSigningModeOpts {
  mode: DocumentsSigningMode;
  date: Moment;
}

export interface CalendarEvent extends UserProcess {
  eventDate: moment.Moment;
  eventDateAsString: string;
  eventTimeAsString: string;
  eventPlace: string;
  eventForeignerName: string;
  eventAssistantName: string;
  eventType: 'COMPANY_OFFICE_VISIT' | 'VOIVO_OFFICE_VISIT' | 'VOIVO_OFFICE_VISIT_WITH_ASSISTANT';
  eventImportant?: boolean;
}

export interface VisitInView {
  [key: string]: { [key: string]: CalendarEvent[] };
}

export interface goToDateVisits {
  date: string;
  type: string;
}

export interface AssignProcessTypeOpts {
  foreignerId: string;
  userProcessId: string;
  processId: string;
  purposeComment: string | null;
  notifyForeigner: boolean;
  userProcessName?: string;
}

export interface UpdateUserProcessServicesOpts {
  userId: string;
  userProcessId: string;
  payload: {
    hasFullHandling: boolean;
    hasAssistantService: boolean;
  };
}

export interface SetAppointmentDateAndPlaceOpts {
  userProcessId: string;
  voivodeshipOfficeId: string;
  immigrationOfficeVisitDate: string;
}
