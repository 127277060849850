import { UserDocument } from '@interfaces';

export type UserDocumentsManagementState = Readonly<{
  loading: boolean;
  errorMessage: string;
  userDocs: UserDocument[];
  userDocsAnswers: Record<string, string>;
}>;

export const initialState: UserDocumentsManagementState = {
  loading: false,
  errorMessage: null,
  userDocs: null,
  userDocsAnswers: null,
};
