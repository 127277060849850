import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { parseError } from '@state/errors.parser';
import * as userProcessManagementActions from './user-documents-management.actions';
import * as managementActions from '@state/management/management.actions';
import { UserDocumentsManagementHttpService } from '@core/http/user-documents-management.http.service';

@Injectable()
export class UserDocumentsManagementEffects {
  constructor(
    private actions$: Actions,
    private readonly userDocsManagementHttp: UserDocumentsManagementHttpService
  ) {}

  getUserProcessSummaryDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userProcessManagementActions.getUserProcessSummaryDocuments),
      switchMap(payload => {
        return this.userDocsManagementHttp.getUserProcessSummaryDocuments(payload).pipe(
          map(userDocs => {
            return userProcessManagementActions.getUserProcessSummaryDocumentsSuccess({
              userDocs,
            });
          }),
          catchError(error => {
            const errorMessage = parseError(error, managementActions.getUserProcessDocuments.type);
            return of(userProcessManagementActions.getUserProcessSummaryDocumentsError({ errorMessage }));
          })
        );
      })
    )
  );

  getAdditionalDocumentsAnswers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userProcessManagementActions.getAdditionalDocumentsAnswers),
      switchMap(payload => {
        return this.userDocsManagementHttp.getAdditionalDocumentsAnswers(payload).pipe(
          map(userDocsAnswers => {
            return userProcessManagementActions.getAdditionalDocumentsAnswersSuccess({
              userDocsAnswers,
            });
          }),
          catchError(error => {
            const errorMessage = parseError(error, managementActions.getUserProcessDocuments.type);
            return of(userProcessManagementActions.getAdditionalDocumentsAnswersError({ errorMessage }));
          })
        );
      })
    )
  );
}
