import { Pagination } from './http';
import { ChangePasswordMode } from '../back-office/back-office-users-list/back-office-users-list.component';
import { PurchaseStatus } from './purchase';
import { SortDirection } from '@angular/material/sort';

export interface BackOfficeGetUsersPayload extends Pagination {
  query?: string;
  roles?: string[];
  languages?: string[];
  companyId?: string[];
  filterByActivationStatus?: string;
}

export interface BackOfficeUsersActivateAccountPayload {
  userID: string;
  password?: string;
  passwordConfirm?: string;
}

export interface BackOfficeGetPartnersPayload {
  query: string;
}

export interface BackOfficeUsersDeactivateAccountPayload {
  userID: string;
}

export interface BackOfficeUsersRestoreAccountPayload {
  userID: string;
}

export interface BackOfficeUsersChangePasswordPayload {
  userID: string;
  mode: ChangePasswordMode;
  password?: string;
  passwordConfirm?: string;
}

export interface BackOfficeUsersSoftDeletePayload {
  usersID: string[];
}

export interface BackOfficeUsersPermDeletePayload {
  usersID: string[];
}

export interface BackOfficeUsersChangePasswordReturn {
  newPassword?: string;
  resetLinkSuccess?: boolean;
  setNewPasswordSuccess?: boolean;
}

export enum FilterByActivationStatus {
  ALL = 'ALL',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
  INACTIVE = 'INACTIVE',
}

export interface BackOfficeGetTransactionsPayload extends Pagination {
  query?: string;
  transactionStatus?: PurchaseStatus;
  startDate?: string;
  endDate?: string;
  partnerIdList?: string[];
  filterByDiscount?: FilterByDiscount;
}

export interface BackOfficeGenerateTransactionsReport {
  orderBy?: string;
  orderDir?: SortDirection;
  query?: string;
  transactionStatus?: PurchaseStatus;
  startDate?: string;
  endDate?: string;
  partnerIdList?: string[];
  filterByDiscount?: FilterByDiscount;
}

export interface BackOfficeGetApplicationsPayload extends Pagination {
  query?: string;
  companyIdList?: string[];
  creatorIdList?: string[];
  processIdList?: string[];
  statusList?: string[];
  modeList?: string[];
  categoryList?: string[];
  startDate?: string;
  endDate?: string;
  filterByDeletionStatus?: FilterByDeletionStatus;
  filterByGained?: FilterByGained;
}

export interface BackOfficeGenerateApplicationReport {
  orderBy?: string;
  orderDir?: string;
  query?: string;
  companyIdList?: string[];
  creatorIdList?: string[];
  processIdList?: string[];
  statusList?: string[];
  modeList?: string[];
  categoryList?: string[];
  startDate?: string;
  endDate?: string;
  filterByDeletionStatus?: FilterByDeletionStatus;
  filterByGained?: FilterByGained;
}

export interface BackOfficeApplicationsSoftDeletePayload {
  applicationsID: string[];
}

export interface BackOfficeApplicationsRestorePayload {
  applicationID: string;
}

export enum FilterByDeletionStatus {
  ALL = 'ALL',
  NOT_DELETED = 'NOT_DELETED',
  DELETED = 'DELETED',
}

export enum FilterByGained {
  ALL = 'ALL',
  BOUGHT = 'BOUGHT',
  ADDED = 'ADDED',
}

export enum FilterByDiscount {
  ALL = 'ALL',
  CODE = 'CODE',
  WITHOUT_CODE = 'WITHOUT_CODE',
}
