<ng-container *ngFor="let menuItem of sideMenuItems">
  <div
    *ngIf="deviceInfo.deviceTypeDetected === 'DESKTOP' || menuItem.showOnMobile"
    [routerLink]="menuItem.isDisabled(myProcess?.status) ? null : menuItem.getRouterLink(myProcess?.id)"
    routerLinkActive="active"
    [class.item-disabled]="menuItem.isDisabled(myProcess?.status)"
    class="menu-item {{ menuItem.class }}"
    nz-tooltip
    [nzTooltipTrigger]="
      isForeigner &&
      menuItem.disabledItemTooltip &&
      (!myProcess?.id || myProcess?.id === 'purchase' || myProcess?.id === 'articles')
        ? 'click'
        : null
    "
    nzTooltipTitle="{{ menuItem.disabledItemTooltip ? (menuItem.disabledItemTooltip | translate) : '' }}"
    [nzTooltipPlacement]="'right'"
    nzTooltipOverlayClassName="disabled-menu-item-tooltip"
    [nzTooltipArrowPointAtCenter]="true">
    <mat-icon *ngIf="menuItem.svgIcon" class="menu-item-icon" [svgIcon]="menuItem.svgIcon"></mat-icon>
    <span>{{ menuItem.mainLabelKey | translate }}</span>
  </div>
</ng-container>

<!-- ARTICLES button - Completely different behaviour. Hardcoding for now. -->
<!-- Visible only to foreigners -->
<div
  *ngIf="!initInProgress && isForeigner"
  (click)="openArticlesModal()"
  [class.active]="!!articlesModalRef"
  class="menu-item"
  nz-tooltip
  #articleHintTooltipWrap="nzTooltip"
  [nzTooltipTitle]="ArticlesHintTooltip"
  [nzTooltipVisible]="!initInProgress && deviceInfo?.deviceTypeDetected === 'DESKTOP' && !seenArticlesHint"
  nzTooltipPlacement="right"
  nzTooltipOverlayClassName="articles-hint-tooltip"
  [nzTooltipArrowPointAtCenter]="true"
  [nzTooltipTrigger]="null">
  <mat-icon class="menu-item-icon" [svgIcon]="'document'"></mat-icon>
  <span>{{ 'MENU.ARTICLES' | translate }}</span>
</div>

<ng-template #ArticlesHintTooltip>
  <div class="articles-hint-wrapper">
    <div class="hint-row">{{ 'ARTICLES.GENERAL_HINT' | translate }}</div>
    <img
      class="close-hint-button"
      src="assets/img/close-icon-white.svg"
      (click)="closeArticlesHint('X_BUTTON')"
      alt="Close button" />
  </div>
</ng-template>
