import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';

import {
  AddNewUserProcessOpts,
  AssignProcessTypeOpts,
  ChangeUserDocStatusOpts,
  ChangeUserProcessStatusOpts,
  ChangeUserProcessTypeOpts,
  DeleteVisitDateOpts,
  EditUserProcess,
  EmailHTMLNotification,
  InAppNotificationType,
  PersonalDetails,
  ProcessDocumentsList,
  SearchProcessesOfUser,
  SearchUserActivityOpts,
  SearchUsersProcessesPayload,
  SendToInpolRequestOpts,
  SendToMosRequestOpts,
  SetVisitDateOpts,
  UpdateDocumentEnabled,
  UpdateDocumentsBulk,
  UpdateForeignerDocumentQuestionAnswerPayload,
  UpdateUserProcessServicesOpts,
  User,
  UserProcess,
  UserProcessChangeType,
} from '@interfaces';
import { AppState } from '@state';
import * as notificationsActions from '@state/notifications/notifications.actions';
import * as managementActions from './management.actions';
import * as managementSelectors from './management.selectors';

@Injectable()
export class ManagementFacade {
  public loading$ = this.store.select(managementSelectors.selectLoading);
  public loadingBasicData$ = this.store.select(managementSelectors.selectLoadingBasicData);
  public loadingDocuments$ = this.store.select(managementSelectors.selectLoadingDocuments);
  public loadingUserProcessChanges$ = this.store.select(managementSelectors.selectLoadingUserProcessChanges);
  public loadingUserActivity$ = this.store.select(managementSelectors.selectLoadingUserActivity);
  public errorMessage$ = this.store.select(managementSelectors.selectErrorMessage);

  public usersProcesses$ = this.store.select(managementSelectors.selectUsersProcesses);
  public usersProcessesPagination$ = this.store.select(managementSelectors.selectUsersProcessesPagination);

  public userProcessBasicData$ = this.store.select(managementSelectors.selectUserProcessBasicData);

  public processesOfUser$ = this.store.select(managementSelectors.selectProcessesOfUser);
  public processesOfUserPagination$ = this.store.select(managementSelectors.selectProcessesOfUserPagination);

  public addNewUserProcessSuccess$ = this.actions.pipe(ofType(managementActions.addNewUserProcessSuccess));

  public assignProcessTypeSuccess$ = this.actions.pipe(ofType(managementActions.assignProcessTypeSuccess));

  public sendFollowUpEmailSuccess$ = this.actions.pipe(ofType(managementActions.sendFollowUpEmailSuccess));
  public sendFollowUpEmailError$ = this.actions.pipe(ofType(managementActions.sendFollowUpEmailError));

  public user$ = this.store.select(managementSelectors.selectUser);
  public userProcess$ = this.store.select(managementSelectors.selectUserProcess);
  public userProcessDocuments$ = this.store.select(managementSelectors.selectUserProcessDocuments);
  public getUserProcessDocumentsSuccess$ = this.actions.pipe(ofType(managementActions.getUserProcessDocumentsSuccess));
  public updateForeignerDocumentQuestionAnswerSuccess$ = this.actions.pipe(
    ofType(managementActions.updateForeignerDocumentQuestionAnswerSuccess)
  );

  public userProcessChanges$ = this.store.select(managementSelectors.selectUserProcessChanges);

  public userActivity$ = this.store.select(managementSelectors.selectUserActivity);
  public userActivityPagination$ = this.store.select(managementSelectors.selectUserActivityPagination);

  public documentsGenerationStatus$ = this.store.select(managementSelectors.selectDocumentsGenerationStatus);

  public updateDocEnabledBulkSuccess$ = this.actions.pipe(ofType(managementActions.updateDocEnabledBulkSuccess));
  public updateDocEnabledSuccess$ = this.actions.pipe(ofType(managementActions.updateDocEnabledSuccess));

  public updatePersonalDetailsSuccess$ = this.actions.pipe(ofType(managementActions.updatePersonalDetailsSuccess));
  public updatePersonalDetailsError$ = this.actions.pipe(ofType(managementActions.updatePersonalDetailsError));
  public setPersonalDetailsVerificationSuccess$ = this.actions.pipe(
    ofType(managementActions.setPersonalDetailsVerificationSuccess)
  );
  public setPersonalDetailsVerificationError$ = this.actions.pipe(
    ofType(managementActions.setPersonalDetailsVerificationError)
  );
  public changeProcessTypeSuccess$ = this.actions.pipe(ofType(managementActions.changeProcessTypeSuccess));
  public changeProcessStatusSuccess$ = this.actions.pipe(ofType(managementActions.changeProcessStatusSuccess));
  public changeUserDocumentStatusSuccess$ = this.actions.pipe(ofType(managementActions.setUserDocumentStatusSuccess));

  public setVisitDateSuccess$ = this.actions.pipe(ofType(managementActions.setVisitDateSuccess));
  public deleteVisitDateSuccess$ = this.actions.pipe(ofType(managementActions.deleteVisitDateSuccess));

  public removeUserProcessSuccess$ = this.actions.pipe(ofType(managementActions.removeUserProcessSuccess));

  public getMergedFileDataSuccess$ = this.actions.pipe(ofType(managementActions.getMergedFileDataSuccess));
  public getMergedFileDataError$ = this.actions.pipe(ofType(managementActions.getMergedFileDataError));

  public printAllDocumentsSuccess$ = this.actions.pipe(ofType(managementActions.printAllDocumentsOfForeingerSuccess));

  public itemsSentForFinalReview$ = this.store.select(managementSelectors.selectItemsSentForFinalReview);
  public loadingItemsSentForFinalReview$ = this.store.select(managementSelectors.selectLoadingItemsInReview);

  public updateDocumentsReviewCommentSuccess$ = this.actions.pipe(
    ofType(managementActions.updateDocumentsReviewCommentSuccess)
  );

  public updateUserProcessBasicDataSuccess$ = this.actions.pipe(
    ofType(managementActions.updateUserProcessBasicDataSuccess)
  );

  public processWithDocuments$ = this.store.select(managementSelectors.selectProcess);
  public loadingProcess$ = this.store.select(managementSelectors.selectLoadingProcess);
  public personalDetailsValidationsConfig$ = this.store.select(managementSelectors.selectDetailsValidationConfig);

  public updateUserProcessServicesSuccess$ = this.actions.pipe(
    ofType(managementActions.updateUserProcessServicesSuccess)
  );
  public updateUserProcessServicesError$ = this.actions.pipe(ofType(managementActions.updateUserProcessServicesError));

  public missingAssistants$ = this.store.select(managementSelectors.selectMissingAssistants);
  public checkingMissingAssistantsInProgress$ = this.store.select(managementSelectors.selectCheckingMissingAssistants);

  constructor(
    private store: Store<AppState>,
    private actions: Actions
  ) {}

  public searchUsersProcesses(rawPayload: SearchUsersProcessesPayload): void {
    const { all, count, ...payload } = rawPayload;
    this.store.dispatch(managementActions.searchUsersProcesses({ payload }));
  }

  public searchProcessesOfUser(payloadWithPagination: SearchProcessesOfUser): void {
    const { all, count, ...payload } = payloadWithPagination;
    this.store.dispatch(managementActions.searchProcessesOfUser({ payload }));
  }

  public getUserProcessBasicData(userProcessId: string): void {
    this.store.dispatch(managementActions.getUserProcessBasicData({ userProcessId }));
  }

  public addNewUserProcess(payload: AddNewUserProcessOpts): void {
    this.store.dispatch(managementActions.addNewUserProcess({ payload }));
  }

  public assignProcessType(opts: AssignProcessTypeOpts): void {
    this.store.dispatch(managementActions.assignProcessType({ opts }));
  }

  public removeUserProcess(userProcessId: string): void {
    this.store.dispatch(managementActions.removeUserProcess({ userProcessId }));
  }

  public restoreUserProcess(userProcessId: string): void {
    this.store.dispatch(managementActions.restoreUserProcess({ userProcessId }));
  }

  public getUser(userId: string): void {
    this.store.dispatch(managementActions.getUser({ userId }));
  }

  public getUser$(userId: string): Observable<User | null> {
    return this.store.select(managementSelectors.selectUser).pipe(
      tap(user => {
        if (!user || user.id !== userId) {
          this.getUser(userId);
        }
      }),
      filter(user => {
        return !(user === null || user.id !== userId);
      })
    );
  }

  public getUserProcess(getOpts: { userId: string; userProcessId: string }): void {
    this.store.dispatch(managementActions.getUserProcess({ ...getOpts }));
  }

  public updateUserProcessBasicData(userProcessId: string, payloadWithUserId: EditUserProcess): void {
    this.store.dispatch(managementActions.updateUserProcessBasicData({ userProcessId, payloadWithUserId }));
  }

  public areDocsGenerated$(userId: string, userProcessId: string): Observable<boolean> {
    const obs = this.actions
      .pipe(ofType(managementActions.areDocsGeneratedSuccess, managementActions.areDocsGeneratedError))
      .pipe(
        take(1),
        map((val: any) => {
          return val?.generated || false;
        })
      );
    this.store.dispatch(managementActions.areDocsGenerated({ userId, userProcessId }));
    return obs;
  }

  public getUserProcess$(getOpts: { userId: string; userProcessId: string }): Observable<UserProcess | null> {
    return this.store.select(managementSelectors.selectUserProcess).pipe(
      tap(userProcess => {
        if (userProcess?.id !== getOpts.userProcessId || userProcess?.userId !== getOpts.userId) {
          this.getUserProcess(getOpts);
        }
      }),
      filter(userProcess => {
        return !(userProcess?.id !== getOpts.userProcessId || userProcess?.userId !== getOpts.userId);
      })
    );
  }

  public getUserProcessDocuments(getOpts: { userId: string; userProcessId: string }): void {
    this.store.dispatch(managementActions.getUserProcessDocuments({ ...getOpts }));
  }

  public getUserProcessDocuments$(getOpts: {
    userId: string;
    userProcessId: string;
  }): Observable<ProcessDocumentsList | null> {
    return this.store.select(managementSelectors.selectUserProcessDocuments).pipe(
      tap(userProcessDocuments => {
        if (userProcessDocuments?.userProcessId !== getOpts.userProcessId) {
          this.getUserProcessDocuments(getOpts);
        }
      }),
      filter(userProcessDocuments => {
        return userProcessDocuments?.userProcessId === getOpts.userProcessId;
      }),
      map(userProcessDocuments => userProcessDocuments.processDocumentsList)
    );
  }

  public getUserProcessChanges(
    userProcessId: string,
    changeTypes?: UserProcessChangeType[],
    changeAuthorId?: string
  ): void {
    this.store.dispatch(managementActions.getUserProcessChanges({ userProcessId, changeTypes, changeAuthorId }));
  }

  public getUserActivity(payload: SearchUserActivityOpts): void {
    this.store.dispatch(managementActions.getUserActivity({ payload }));
  }

  public downloadErrorScreenshot(screenshotPath: string): void {
    this.store.dispatch(managementActions.downloadErrorScreenshot({ screenshotPath }));
  }

  public downloadINPOLRecording(recordingPath: string): void {
    this.store.dispatch(managementActions.downloadINPOLRecording({ recordingPath }));
  }

  public getDetailsValidationConfig(foreignerId: string, userProcessId: string): void {
    this.store.dispatch(managementActions.getDetailsValidationConfig({ foreignerId, userProcessId }));
  }

  public updatePersonalDetails(updateOpts: {
    userId: string;
    userProcessId: string;
    personalDetails: PersonalDetails;
  }): void {
    this.store.dispatch(managementActions.updatePersonalDetails(updateOpts));
  }

  public setPersonalDetailsVerification(opts: { userId: string; userProcessId: string; verified: boolean }): void {
    this.store.dispatch(managementActions.setPersonalDetailsVerification(opts));
  }

  public changeUserProcessType(opts: ChangeUserProcessTypeOpts): void {
    this.store.dispatch(managementActions.changeProcessType(opts));
  }

  public changeUserProcessStatus(opts: ChangeUserProcessStatusOpts): void {
    this.store.dispatch(
      notificationsActions.markAllOfTypeAsRead({
        inAppNotificationType: InAppNotificationType.FOREIGNER_SENT_APPLICATION_TO_VERIFICATION,
        relatedForeignerId: opts.userId,
        relatedUserProcessId: opts.userProcessId,
      })
    );

    this.store.dispatch(managementActions.changeProcessStatus(opts));
  }

  public setUserDocumentStatus(opts: ChangeUserDocStatusOpts): void {
    this.store.dispatch(managementActions.setUserDocumentStatus({ ...opts }));
  }

  public getDocumentsGenerationStatus(userId: string, userProcessId: string): void {
    this.store.dispatch(managementActions.getDocumentsGenerationStatus({ userId, userProcessId }));
  }

  public generateDocumentsNow(userId: string, userProcessId: string): void {
    this.store.dispatch(managementActions.generateDocumentsNow({ userId, userProcessId }));
  }

  public printAllDocumentsOfForeigner(userId: string, userProcessId: string): void {
    this.store.dispatch(managementActions.printAllDocumentsOfForeinger({ userId, userProcessId }));
  }

  public sendDataToInpol(opts: SendToInpolRequestOpts): void {
    this.store.dispatch(managementActions.sendDataToInpol({ opts }));
  }

  public sendDataToMos(opts: SendToMosRequestOpts): void {
    this.store.dispatch(managementActions.sendDataToMos({ opts }));
  }

  public setVisitDate(opts: SetVisitDateOpts): void {
    this.store.dispatch(managementActions.setVisitDate({ ...opts }));
  }

  public deleteVisitDate(opts: DeleteVisitDateOpts): void {
    this.store.dispatch(managementActions.deleteVisitDate({ ...opts }));
  }

  public confirmVoivoChange(userId: string, userProcessId: string): void {
    this.store.dispatch(managementActions.confirmVoivoChange({ userId, userProcessId }));
  }

  public updateDocEnabled(opts: UpdateDocumentEnabled): void {
    this.store.dispatch(managementActions.updateDocEnabled({ opts }));
  }

  public updateDocEnabledBulk(opts: UpdateDocumentsBulk): void {
    this.store.dispatch(managementActions.updateDocEnabledBulk({ opts }));
  }

  public getMergedFileData(userId: string, userProcessId: string): void {
    this.store.dispatch(managementActions.getMergedFileData({ userId, userProcessId }));
  }

  public updateForeignerDocumentQuestionAnswer(opts: UpdateForeignerDocumentQuestionAnswerPayload): void {
    this.store.dispatch(managementActions.updateForeignerDocumentQuestionAnswer({ opts }));
  }

  public getItemsSentForFinalReview(userProcessId: string): void {
    this.store.dispatch(managementActions.getItemsSentForFinalReview({ userProcessId }));
  }

  public downloadItemSentForFinalReview(opts: { userProcessId: string; userAssetId: string }): void {
    this.store.dispatch(managementActions.downloadItemSentForFinalReview({ opts }));
  }

  public updateDocumentsReviewComment(opts: { userProcessId: string; userId: string; comment: string }): void {
    this.store.dispatch(managementActions.updateDocumentsReviewComment({ opts }));
  }

  public getProcessWithDocuments(processId: string): void {
    this.store.dispatch(managementActions.getProcessWithDocs({ processId }));
  }

  public updateUserProcessServices(opts: UpdateUserProcessServicesOpts): void {
    this.store.dispatch(managementActions.updateUserProcessServices({ opts }));
  }

  public isAnyProcessOfMineMissingAssistant(): void {
    this.store.dispatch(managementActions.isAnyProcessOfMineMissingAssistant());
  }

  public sendFollowUpEmail(usersId: string[], messages: EmailHTMLNotification): void {
    this.store.dispatch(managementActions.sendFollowUpEmail({ usersId, messages }));
  }
}
