<div class="header-buttons">
  <button nz-button nzType="text" class="go-back-button" (click)="goBack()">
    <ng-container *ngIf="(currStepOfModal$ | async) === STEP_OF_MODAL.ARTICLES_LIST; else StandardGoBack">
      {{ 'ARTICLES.CLOSE' | translate }}
    </ng-container>
    <ng-template #StandardGoBack>
      <span nz-icon nzType="left"></span>
      {{ 'ARTICLES.GO_BACK' | translate }}
    </ng-template>
  </button>

  <button mat-icon-button aria-label="Close button" tabindex="-1">
    <mat-icon (click)="close()">close</mat-icon>
  </button>
</div>
<mat-dialog-content #dialogContent>
  <ng-container [ngSwitch]="currStepOfModal$ | async">
    <ng-container *ngSwitchCase="STEP_OF_MODAL.ARTICLES_LIST">
      <app-articles-list></app-articles-list>
    </ng-container>
    <ng-container *ngSwitchCase="STEP_OF_MODAL.CONTENT">
      <app-article-content></app-article-content>
    </ng-container>
  </ng-container>
</mat-dialog-content>
