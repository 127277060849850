import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ManagementState } from './management.state';

export const selectManagementState = createFeatureSelector<ManagementState>('management');

export const selectUsersProcesses = createSelector(selectManagementState, state => state.usersProcesses);

export const selectUsersProcessesPagination = createSelector(
  selectManagementState,
  state => state.usersProcessesPagination
);

export const selectProcessesOfUser = createSelector(selectManagementState, state => state.processesOfUser);

export const selectProcessesOfUserPagination = createSelector(
  selectManagementState,
  state => state.processesOfUserPagination
);

export const selectLoading = createSelector(selectManagementState, state => state.loading);

export const selectLoadingBasicData = createSelector(selectManagementState, state => state.loadingBasicData);

export const selectUserProcessBasicData = createSelector(selectManagementState, state => state.userProcessBasicData);

export const selectErrorMessage = createSelector(selectManagementState, state => state.errorMessage);

export const selectUser = createSelector(selectManagementState, state => state.user);

export const selectUserProcess = createSelector(selectManagementState, state => state.userProcess);

export const selectDetailsValidationConfig = createSelector(
  selectManagementState,
  state => state.personalDetailsValidationConfig
);

export const selectUserProcessDocuments = createSelector(selectManagementState, state => state.userProcessDocuments);

export const selectLoadingDocuments = createSelector(selectManagementState, state => state.loadingDocuments);

export const selectDocumentsGenerationStatus = createSelector(
  selectManagementState,
  state => state.userProcessDocuments.documentsGenerationStatus
);

export const selectLoadingUserProcessChanges = createSelector(
  selectManagementState,
  state => state.loadingUserProcessChanges
);

export const selectUserProcessChanges = createSelector(selectManagementState, state => state.userProcessChanges);

export const selectLoadingUserActivity = createSelector(selectManagementState, state => state.loadingUserActivity);
export const selectUserActivity = createSelector(selectManagementState, state => state.userActivity);
export const selectUserActivityPagination = createSelector(
  selectManagementState,
  state => state.userActivityPagination
);

export const selectItemsSentForFinalReview = createSelector(
  selectManagementState,
  state => state.itemsSentForFinalReview
);
export const selectLoadingItemsInReview = createSelector(selectManagementState, state => state.loadingItemsInReview);

export const selectProcess = createSelector(selectManagementState, state => state.processWithDocuments);
export const selectLoadingProcess = createSelector(selectManagementState, state => state.loadingProcess);
export const selectMissingAssistants = createSelector(
  selectManagementState,
  state => state.anyMyProcessesMissingAssistant
);
export const selectCheckingMissingAssistants = createSelector(
  selectManagementState,
  state => state.checkingMissingAssistantInProgress
);
