import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '@state';
import * as managementActions from '@state/user-documents-management/user-documents-management.actions';
import * as managementSelectors from '@state/user-documents-management/user-documents-management.selectors';

@Injectable()
export class UserDocumentsManagementFacade {
  public userDocs$ = this.store.select(managementSelectors.selectUserDocs);
  public userDocsAnswers$ = this.store.select(managementSelectors.selectUserDocsAnswers);

  constructor(
    private store: Store<AppState>,
    private actions: Actions
  ) {}

  public getUserProcessSummaryDocuments(payload: { userProcessId: string }): void {
    this.store.dispatch(managementActions.getUserProcessSummaryDocuments({ ...payload }));
  }

  public getAdditionalDocumentsAnswers(payload: { userProcessId: string; userDocsIds: string[] }): void {
    this.store.dispatch(managementActions.getAdditionalDocumentsAnswers({ ...payload }));
  }
}
