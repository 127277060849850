import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommonsFacade } from '@state/commons';
import { NzMessageService } from 'ng-zorro-antd/message';
import { catchError, of, ReplaySubject, switchMap, takeUntil } from 'rxjs';
import { ArticlesService } from '../articles.service';

@Component({
  selector: 'app-article-content',
  templateUrl: './article-content.component.html',
  styleUrls: ['./article-content.component.scss'],
})
export class ArticleContentComponent implements OnInit, OnDestroy {
  private readonly destroy$: ReplaySubject<boolean> = new ReplaySubject();

  @ViewChild('ArticleContentContainer') articleContentContainer: ElementRef<HTMLElement>;

  public CONTENT_ID = 'article-content-img';
  public CONTENT_CONTAINER_ID = 'article-content-container';

  public loading = false;

  constructor(
    private readonly articlesService: ArticlesService,
    private readonly nzMessage: NzMessageService,
    private readonly translateService: TranslateService,
    private readonly commonsFacade: CommonsFacade
  ) {}

  ngOnInit(): void {
    this.articlesService.selectedArticleContent$
      .asObservable()
      .pipe(
        switchMap(({ article, content }) => {
          const articleId = article?.id;
          const articleContentId = content?.id;

          if (!articleId || !articleContentId) {
            return of(null);
          }

          this.loading = true;
          return this.articlesService.getArticleContentData({ articleId, articleContentId });
        }),
        catchError(err => {
          this.nzMessage.error(this.translateService.instant('ARTICLES.ERROR_FETCHING_ARTICLE_DATA'));
          this.loading = false;
          return of(null);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(data => {
        if (!data) {
          return;
        }

        this.createImgElem(data.body);
        setTimeout(() => {
          this.loading = false;
        }, 300);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private destroyCurrentImage(): void {
    const currImage = this.articleContentContainer.nativeElement?.children?.item(0);
    if (!currImage) {
      return;
    }

    currImage.remove();
    return;
  }

  private createImgElem(data: Blob): void {
    this.destroyCurrentImage();

    if (!this.articleContentContainer?.nativeElement) {
      console.error('ERROR_REFERENCING_CONTENT_CONTAINER_ELEMENT');
      return;
    }
    const url = URL.createObjectURL(data);
    const imgElem = new Image();
    imgElem.setAttribute('id', this.CONTENT_ID);
    imgElem.setAttribute('style', 'width: 90%; max-width: 1200px; height: auto;');
    imgElem.onload = () => {
      URL.revokeObjectURL(imgElem.src);
      this.articleContentContainer.nativeElement.appendChild(imgElem);
    };
    imgElem.src = url;
  }
}
