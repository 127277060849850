import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@state';
import {
  BackOfficeApplicationsRestorePayload,
  BackOfficeApplicationsSoftDeletePayload,
  BackOfficeGenerateApplicationReport,
  BackOfficeGenerateTransactionsReport,
  BackOfficeGetApplicationsPayload,
  BackOfficeGetPartnersPayload,
  BackOfficeGetTransactionsPayload,
  BackOfficeGetUsersPayload,
  BackOfficeUsersActivateAccountPayload,
  BackOfficeUsersChangePasswordPayload,
  BackOfficeUsersDeactivateAccountPayload,
  BackOfficeUsersPermDeletePayload,
  BackOfficeUsersRestoreAccountPayload,
  BackOfficeUsersSoftDeletePayload,
  User,
} from '@interfaces';
import * as backOfficeActions from './back-office.actions';
import * as backOfficeSelectors from './back-office.selectors';
import { Actions, ofType } from '@ngrx/effects';

@Injectable()
export class BackOfficeFacade {
  public roles$ = this.store.select(backOfficeSelectors.selectRoles);
  public languages$ = this.store.select(backOfficeSelectors.selectLanguages);
  public companies$ = this.store.select(backOfficeSelectors.selectCompanies);
  public employees$ = this.store.select(backOfficeSelectors.selectEmployees);
  public partners$ = this.store.select(backOfficeSelectors.selectPartners);
  public selectedPartners$ = this.store.select(backOfficeSelectors.selectSelectedPartners);

  public getRolesSuccess$ = this.actions.pipe(ofType(backOfficeActions.getRolesSuccess));
  public getCompaniesSuccess$ = this.actions.pipe(ofType(backOfficeActions.getCompaniesSuccess));
  public getEmployeesSuccess$ = this.actions.pipe(ofType(backOfficeActions.getEmployeesSuccess));
  public getPartnersSuccess$ = this.actions.pipe(ofType(backOfficeActions.getPartnersSuccess));

  public users$ = this.store.select(backOfficeSelectors.selectUsers);
  public usersLoading$ = this.store.select(backOfficeSelectors.selectUsersLoading);
  public usersErrorMessage$ = this.store.select(backOfficeSelectors.selectUsersErrorMessage);
  public usersPagination$ = this.store.select(backOfficeSelectors.selectUsersLoadedPagination);

  public usersActivateAccountSuccess$ = this.actions.pipe(ofType(backOfficeActions.usersActivateAccountSuccess));
  public usersActivateAccountError$ = this.actions.pipe(ofType(backOfficeActions.usersActivateAccountError));
  public usersDeactivateAccountSuccess$ = this.actions.pipe(ofType(backOfficeActions.usersDeactivateAccountSuccess));
  public usersDeactivateAccountError$ = this.actions.pipe(ofType(backOfficeActions.usersDeactivateAccountError));
  public usersSoftDeleteSuccess$ = this.actions.pipe(ofType(backOfficeActions.usersSoftDeleteSuccess));
  public usersSoftDeleteError$ = this.actions.pipe(ofType(backOfficeActions.usersSoftDeleteError));
  public usersRestoreAccountSuccess$ = this.actions.pipe(ofType(backOfficeActions.usersRestoreAccountSuccess));
  public usersRestoreAccountError$ = this.actions.pipe(ofType(backOfficeActions.usersRestoreAccountError));
  public usersChangePasswordSuccess$ = this.actions.pipe(ofType(backOfficeActions.usersChangePasswordSuccess));
  public usersChangePasswordError$ = this.actions.pipe(ofType(backOfficeActions.usersChangePasswordError));

  public transactions$ = this.store.select(backOfficeSelectors.selectTransactions);
  public transactionsLoading$ = this.store.select(backOfficeSelectors.selectTransactionsLoading);
  public transactionsErrorMessage$ = this.store.select(backOfficeSelectors.selectTransactionsErrorMessage);
  public transactionsPagination$ = this.store.select(backOfficeSelectors.selectTransactionsLoadedPagination);
  public transactionsDownloading$ = this.store.select(backOfficeSelectors.selectTransactionsDownloading);
  public transactionsGenerating$ = this.store.select(backOfficeSelectors.selectTransactionsGenerating);

  public applications$ = this.store.select(backOfficeSelectors.selectApplications);
  public applicationsLoading$ = this.store.select(backOfficeSelectors.selectApplicationsLoading);
  public applicationsErrorMessage$ = this.store.select(backOfficeSelectors.selectApplicationsErrorMessage);
  public applicationsPagination$ = this.store.select(backOfficeSelectors.selectApplicationsLoadedPagination);
  public applicationsGenerating$ = this.store.select(backOfficeSelectors.selectApplicationsGenerating);
  public applicationsSoftDeleteSuccess$ = this.actions.pipe(ofType(backOfficeActions.applicationsSoftDeleteSuccess));
  public applicationsSoftDeleteError$ = this.actions.pipe(ofType(backOfficeActions.applicationsSoftDeleteError));
  public applicationsRestoreSuccess$ = this.actions.pipe(ofType(backOfficeActions.applicationsRestoreSuccess));
  public applicationsRestoreError$ = this.actions.pipe(ofType(backOfficeActions.applicationsRestoreeError));

  public partnersLoading$ = this.store.select(backOfficeSelectors.selectPartnersLoading);

  constructor(
    private store: Store<AppState>,
    private actions: Actions
  ) {}

  public getCompanies(): void {
    this.store.dispatch(backOfficeActions.getCompanies());
  }

  public getRoles(): void {
    this.store.dispatch(backOfficeActions.getRoles());
  }

  public getLanguages(): void {
    this.store.dispatch(backOfficeActions.getLanguages());
  }

  public getEmployees(): void {
    this.store.dispatch(backOfficeActions.getEmployees());
  }

  public getPartners(payload: BackOfficeGetPartnersPayload): void {
    this.store.dispatch(backOfficeActions.getPartners({ payload }));
  }

  public setSelectedPartners(payload: { selectedPartners: Partial<User>[] }): void {
    this.store.dispatch(backOfficeActions.setSelectedPartners({ payload }));
  }

  // ############
  // Users
  // ############

  public getUsers(payload: BackOfficeGetUsersPayload): void {
    this.store.dispatch(backOfficeActions.getUsers({ payload }));
  }

  public usersActivateAccount(payload: BackOfficeUsersActivateAccountPayload): void {
    this.store.dispatch(backOfficeActions.usersActivateAccount({ payload }));
  }

  public usersDeactivateAccount(payload: BackOfficeUsersDeactivateAccountPayload): void {
    this.store.dispatch(backOfficeActions.usersDeactivateAccount({ payload }));
  }

  public usersRestoreAccount(payload: BackOfficeUsersRestoreAccountPayload): void {
    this.store.dispatch(backOfficeActions.usersRestoreAccount({ payload }));
  }

  public usersSoftDelete(payload: BackOfficeUsersSoftDeletePayload): void {
    this.store.dispatch(backOfficeActions.usersSoftDelete({ payload }));
  }

  public usersPermDelete(payload: BackOfficeUsersPermDeletePayload): void {
    this.store.dispatch(backOfficeActions.usersPermDelete({ payload }));
  }

  public usersChangePassword(payload: BackOfficeUsersChangePasswordPayload): void {
    this.store.dispatch(backOfficeActions.usersChangePassword({ payload }));
  }

  // ############
  // Transactions
  // ############

  public getTransactions(payload: BackOfficeGetTransactionsPayload): void {
    this.store.dispatch(backOfficeActions.getTransactions({ payload }));
  }

  public transactionsDownloadPurchaseDocument(transactionID: string): void {
    this.store.dispatch(backOfficeActions.transactionsDownloadPurchaseDocument({ transactionID }));
  }

  public transactionsGenerateReport(payload: BackOfficeGenerateTransactionsReport): void {
    this.store.dispatch(backOfficeActions.transactionsGenerateReport({ payload }));
  }

  // ############
  // Applications
  // ############

  public getApplications(payload: BackOfficeGetApplicationsPayload): void {
    this.store.dispatch(backOfficeActions.getApplications({ payload }));
  }

  public applicationsGenerateReport(payload: BackOfficeGenerateApplicationReport): void {
    this.store.dispatch(backOfficeActions.applicationsGenerateReport({ payload }));
  }

  public applicationsSoftDelete(payload: BackOfficeApplicationsSoftDeletePayload): void {
    this.store.dispatch(backOfficeActions.applicationsSoftDelete({ payload }));
  }

  public applicationsRestore(payload: BackOfficeApplicationsRestorePayload): void {
    this.store.dispatch(backOfficeActions.applicationsRestore({ payload }));
  }
}
