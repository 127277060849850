import { Component, OnDestroy, OnInit } from '@angular/core';
import { Article } from '@interfaces';
import { SnackbarService } from '@shared/snack-bar/snack-bar.service';
import { catchError, of, ReplaySubject, take, takeUntil } from 'rxjs';
import { ArticlesService } from '../articles.service';

@Component({
  selector: 'app-articles-list',
  templateUrl: './articles-list.component.html',
  styleUrls: ['./articles-list.component.scss'],
})
export class ArticlesListComponent implements OnInit, OnDestroy {
  private readonly destroy$: ReplaySubject<boolean> = new ReplaySubject(1);

  public loading: boolean;
  public articles: Article[] = [];

  public backSideShownFor: string = null;

  constructor(
    private readonly articlesService: ArticlesService,
    private readonly snackService: SnackbarService
  ) {}

  ngOnInit(): void {
    this.loading = true;

    this.articlesService
      .getArticlesList()
      .pipe(
        take(1),
        catchError(() => {
          this.snackService.showError('ARTICLES.ERROR_FETCHING_ARTICLES_LIST');
          this.loading = false;
          return of(null);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(articlesList => {
        if (!articlesList) {
          return;
        }

        this.articles = articlesList;
        this.loading = false;
      });
  }

  public bgClick(): void {
    this.articlesService.showBackSideOf$.next(null);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
