import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { AppState } from '@state';
import { GetUserAssetsOpts } from '@interfaces';

import * as userFileActions from './user-files.actions';
import * as userFileSelectors from './user-files.selectors';

@Injectable()
export class UserFilesFacade {
  public loading$ = this.store.select(userFileSelectors.selectLoading);
  public downloadingFile$ = this.store.select(userFileSelectors.selectDownloadingFile);
  public filesOfDocuments$ = this.store.select(userFileSelectors.selectFilesOfDocuments);
  public userAssets$ = this.store.select(userFileSelectors.selectUserAssets);
  public sort$ = this.store.select(userFileSelectors.selectSort);
  public upload$ = this.store.select(userFileSelectors.selectUpload);

  public downloadAssetSuccess$ = this.actions.pipe(ofType(userFileActions.downloadAssetSuccess));
  public downloadAssetError$ = this.actions.pipe(ofType(userFileActions.downloadAssetError));

  constructor(
    private store: Store<AppState>,
    private actions: Actions
  ) {}

  public getFilesOfDocuments(opts: GetUserAssetsOpts): void {
    this.store.dispatch(userFileActions.getFilesOfUserDocuments({ opts }));
  }

  public getUserAssets(opts: GetUserAssetsOpts): void {
    this.store.dispatch(userFileActions.getUserAssets({ opts }));
  }

  public downloadAsset(userAssetId: string): void {
    this.store.dispatch(userFileActions.downloadAsset({ userAssetId }));
  }
}
