<div
  class="article-card article-{{ article.id }}"
  [class.bring-to-top]="bringToTop"
  [class.show-back]="showBack"
  *ngIf="article?.id && availableContents?.length"
  (click)="cardClicked(); $event.stopPropagation()">
  <div class="article-card-side side-front">
    <h3 [innerHTML]="article.title[currLangKey]"></h3>
  </div>

  <div
    class="article-card-side side-back"
    [class.increase-height]="increaseHeight"
    [ngStyle]="{ height: setHeightTo ? setHeightTo + 'px' : null }"
    *ngIf="article.hasVoivodeshipIsolation">
    <h3 [innerHTML]="'ARTICLES.CHOOSE_VOIVODESHIP' | translate"></h3>

    <ng-scrollbar class="voivos-list" (click)="$event.stopPropagation()">
      <div
        *ngFor="let content of availableContents"
        class="voivo-item"
        (click)="$event.stopPropagation(); voivoSelected(content)">
        {{ content.voivodeship.name }}
      </div>
    </ng-scrollbar>
  </div>
</div>
