import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AnalyticsService } from '@core/services/analytics.service';
import { DeviceInfoService } from '@core/services/device-info.service';
import { environment } from '@environment';
import { AllDeviceInfo, Article, ArticleContent, GetArticleContentDataOpts } from '@interfaces';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject, take, takeUntil } from 'rxjs';
import { ArticlesComponent } from './articles.component';

export enum STEP_OF_MODAL {
  ARTICLES_LIST = 'ARTICLES_LIST',
  VOIVOS_LIST = 'VOIVOS_LIST',
  CONTENT = 'CONTENT',
}

@Injectable()
export class ArticlesService implements OnDestroy {
  private destroy$: ReplaySubject<boolean> = new ReplaySubject(1);
  private API_URL = environment.API_URL;

  public deviceInfo: AllDeviceInfo;

  public showBackSideOf$ = new Subject();
  public matDialogRef: MatDialogRef<ArticlesComponent>;

  public currStepOfModal$ = new BehaviorSubject<STEP_OF_MODAL>(STEP_OF_MODAL.ARTICLES_LIST);
  public scrollToElem$ = new Subject<string>();

  public selectedArticleContent$ = new BehaviorSubject<{
    article: Article;
    content: ArticleContent;
  }>({ article: null, content: null });

  private dialogWidth = '960px';
  private dialogHeight = '90vh';

  constructor(
    private dialog: MatDialog,
    private readonly deviceInfoService: DeviceInfoService,
    private readonly http: HttpClient,
    private readonly translateService: TranslateService,
    private readonly analyticsService: AnalyticsService
  ) {
    this.deviceInfo = this.deviceInfoService.getInfo();
    if (this.deviceInfo.deviceTypeDetected === 'PHONE') {
      this.dialogWidth = '97dvw';
      this.dialogHeight = '97dvh';
    }

    this.translateService.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.currStepOfModal$.next(STEP_OF_MODAL.ARTICLES_LIST);
      this.selectedArticleContent$.next({ article: null, content: null });
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public open(): MatDialogRef<ArticlesComponent> {
    this.matDialogRef = this.dialog.open(ArticlesComponent, {
      width: this.dialogWidth,
      maxWidth: this.dialogWidth,
      height: this.dialogHeight,
      maxHeight: this.dialogHeight,
      minHeight: '500px',
      panelClass: ['articles-modal'],
    });

    this.analyticsService.trackEvent('user_event', 'user_opened_articles');

    this.matDialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        this.currStepOfModal$.next(STEP_OF_MODAL.ARTICLES_LIST);
      });
    return this.matDialogRef;
  }

  public close(): void {
    this.matDialogRef.close();
  }

  public getArticlesList(): Observable<Article[]> {
    const url = `${this.API_URL}/articles`;
    return this.http.get<Article[]>(url);
  }

  public getArticleDetails(articleId: string): Observable<Article> {
    const url = `${this.API_URL}/articles/${articleId}`;
    return this.http.get<Article>(url);
  }

  public getArticleContentsInVoivo(articleId: string, voivodeshipId?: string): Observable<ArticleContent[]> {
    const url = `${this.API_URL}/articles/${articleId}/article-contents/voivodeship/${voivodeshipId || ''}`;
    return this.http.get<ArticleContent[]>(url);
  }

  public getArticleContentData(opts: GetArticleContentDataOpts): Observable<HttpResponse<Blob>> {
    const url = `${this.API_URL}/articles/${opts.articleId}/content-data/${opts.articleContentId}`;
    return this.http.get(url, { responseType: 'blob', observe: 'response' });
  }
}
