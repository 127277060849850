import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { debounce } from 'lodash-es';
import { lastValueFrom, merge, Observable, ReplaySubject } from 'rxjs';
import { delay, filter, map, take, takeUntil } from 'rxjs/operators';

import { MatSelectChange } from '@angular/material/select';
import { Title } from '@angular/platform-browser';
import { allEmployeeRoles, AvailableLanguages, roleToPath } from '@constants';
import { WebsocketService } from '@core/services/websocket.service';
import { environment } from '@environment';
import { AllDeviceInfo, GUIDE_NAME, InfoBarState, MaintenanceInfo, ROLES_KEYS, User, UserProcess } from '@interfaces';
import { TranslateService } from '@ngx-translate/core';
import { InAppNotificationsFacade } from '@state/notifications';
import { RouterFacade } from '@state/router';
import { UserAuthFacade } from '@state/user-auth';
import { UserProcessFacade } from '@state/user-process';
import { LayoutService } from './layout.service';
import { MenuItem, sideMenuConfig } from './side-menu.config';

import { animate, style, transition, trigger } from '@angular/animations';
import { AnalyticsService } from '@core/services/analytics.service';
import { DeviceInfoService } from '@core/services/device-info.service';
import { LocalStorageService } from '@core/services/local-storage.service';
import { SessionTimeoutService } from '@core/services/session-timeout.service';
import { MyPartnerAccountDrawerService } from '@shared/my-partner-account-drawer/my-partner-account-drawer.service';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('400ms ease-in', style({ transform: 'translateX(0%)' })),
      ]),
      transition(':leave', [animate('200ms ease-in', style({ transform: 'translateX(-100%)' }))]),
    ]),
  ],
})
export class LayoutComponent implements OnInit, AfterViewInit, OnDestroy {
  public myself$: Observable<User | null> = this.userAuthFacade.myself$;
  public isAdministrator = false;
  public isForeigner = false;
  public myProcesses$ = this.userProcessFacade.myProcesses$;
  public myProcess$ = this.userProcessFacade.myProcess$;
  public maintenanceInfo$: Observable<MaintenanceInfo> = this.inAppNotifications.maintenanceInfo$;

  @ViewChild('contentWrapper') contentWrapper: ElementRef;
  public showTopbarShadow = false;

  public sideMenuItems: MenuItem[] = [];
  public myProcess: UserProcess;

  public version = environment.version;
  public showProgressBar = false;
  public progressPercentage = 0;
  public showBottomFixedBar = false;

  public showOpenChatButton = true;

  public showNotifications = false;
  public unreadNotificationsCount = 0;
  public newNotificationReceived = false;

  public infoBarState: InfoBarState = { show: false, type: 'info' };

  private titleBlinkingInterval: any;

  private destroy$: ReplaySubject<boolean> = new ReplaySubject(1);

  private PAGE_TITLE = environment.PAGE_TITLE;

  public foreignerMenuItems: any[] = [];
  public addingNewProcess: null | { id: string } = null;
  public GUIDE_NAME = GUIDE_NAME;
  public currentLang: AvailableLanguages;
  public shouldShowOffice = false;

  public userShouldSeeChatsList = false;

  public showSidebar = false;
  public sidebarWidth = '95vw';
  public deviceInfo: AllDeviceInfo;
  public allowedToParticipateInPartnerProgram: boolean;
  public partnerAccountDrawerRef: NzDrawerRef;
  public seenArticlesHint: boolean;

  constructor(
    private readonly userAuthFacade: UserAuthFacade,
    private readonly userProcessFacade: UserProcessFacade,
    private readonly routerFacade: RouterFacade,
    private readonly router: Router,
    private readonly layoutService: LayoutService,
    private readonly inAppNotifications: InAppNotificationsFacade,
    private readonly websocket: WebsocketService,
    private readonly titleService: Title,
    private readonly translateService: TranslateService,
    private readonly sessionTimeout: SessionTimeoutService,
    private readonly deviceInfoService: DeviceInfoService,
    private readonly analyticsService: AnalyticsService,
    private readonly myPartnerAccountDrawer: MyPartnerAccountDrawerService,
    private readonly lsService: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.deviceInfo = this.deviceInfoService.getInfo();

    this.deviceInfoService.infoEmitter.pipe(takeUntil(this.destroy$)).subscribe(info => {
      this.deviceInfo = info;
      if (this.deviceInfo.deviceTypeDetected === 'DESKTOP') {
        this.toggleSidebar(false);
      }
    });
    this.startSubscribeRouter();

    this.sessionTimeout.startIdleWatch();
    this.layoutService.setupGuideDefaultOpts();

    this.currentLang = this.translateService.currentLang as AvailableLanguages;
    this.translateService.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(({ lang }) => {
      this.currentLang = lang as AvailableLanguages;
    });

    this.lsService.articlesHintSeen$.pipe(takeUntil(this.destroy$)).subscribe(seen => {
      this.seenArticlesHint = seen;
    });

    this.userProcessFacade.myProcess$
      .pipe(
        filter(myProcess => !!myProcess?.id),
        takeUntil(this.destroy$)
      )
      .subscribe(myProcess => {
        this.myProcess = myProcess;
        this.addingNewProcess = null;
      });

    this.myself$
      .pipe(
        filter(user => !!user?.role),
        take(1),
        takeUntil(this.destroy$)
      )
      .subscribe(user => {
        this.inAppNotifications.getMaintenanceInfo();
        const currentUserRoleKey = user.role.key as ROLES_KEYS;
        this.isAdministrator = currentUserRoleKey === ROLES_KEYS.Admin;
        this.isForeigner = currentUserRoleKey === ROLES_KEYS.Foreigner;
        this.sideMenuItems = sideMenuConfig[currentUserRoleKey];

        const rolesAllowedToParticipate = [ROLES_KEYS.Foreigner];
        this.allowedToParticipateInPartnerProgram =
          rolesAllowedToParticipate.includes(currentUserRoleKey) && user.company?.purchaseEnabled;

        // show office field for these roles
        this.shouldShowOffice = allEmployeeRoles.includes(user.role.key as ROLES_KEYS);

        // show support-chat for these roles
        this.userShouldSeeChatsList = allEmployeeRoles.includes(user.role.key as ROLES_KEYS);

        if (this.isForeigner) {
          // if there is no userProcess picked already - reroute to the first one - if exists
          // if does not exist - reroute to the userProcess creation
          this.myProcesses$
            .pipe(
              filter(myProcesses => myProcesses !== null),
              take(1),
              takeUntil(this.destroy$)
            )
            .subscribe(myProcesses => {
              const hasProcesses = !!myProcesses?.length;
              const canAddOwnProcesses = user?.allowedToAddOwnUserProcesses;
              const processesLImit = user?.userProcessesLimit;
              const companyPurchaseEnabled = user?.company?.purchaseEnabled;

              if (this.router.url.includes('user-process-form')) {
                if (canAddOwnProcesses && hasProcesses && myProcesses.length < processesLImit) {
                  this.goToApplication({ id: 'new' } as UserProcess);
                } else {
                  this.goToApplication({ id: 'purchase' } as UserProcess);
                }
              }

              if (this.router.url.includes('purchase')) {
                // User came back from payment if url includes `:purchaseId/status`
                // In this case we should not make any redirects (inside .goToApplication)
                // thus we're checking here if this is products-selection or after-payment page
                // if there is no 'status' in url -> this is products-selection
                const shouldChangeRoute = !this.router.url.includes('status?backFromOperator=true');
                this.goToApplication({ id: 'purchase' } as UserProcess, shouldChangeRoute);
              }

              if (this.router.url.length > 1 && this.router.url !== '/user-process') {
                return;
              }

              // user is in blank view
              if (this.router.url.length <= 1 || this.router.url === '/user-process') {
                let appToGo = hasProcesses ? myProcesses[0] : ({ id: 'new' } as UserProcess);
                if (!hasProcesses && canAddOwnProcesses) {
                  appToGo = { id: 'new' } as UserProcess;
                }
                if (!hasProcesses && !canAddOwnProcesses && companyPurchaseEnabled) {
                  appToGo = { id: 'purchase' } as UserProcess;
                }
                if (!hasProcesses && !canAddOwnProcesses && !companyPurchaseEnabled) {
                  appToGo = { id: 'no-items' } as UserProcess;
                }
                if (hasProcesses) {
                  appToGo = myProcesses[0];
                }

                this.goToApplication(appToGo);
              }
            });
          this.userProcessFacade.getMyProcesses();
        }

        // ---- SHOULD RUUN ONLY ONCE ON LAYOUT INIT!!!
        this.websocket.init();
        this.listenForLayoutChanges();
        this.setupNotifications();
      });

    this.myself$
      .pipe(
        filter(user => !!user?.role),
        takeUntil(this.destroy$)
      )
      .subscribe(user => {
        const currentUserRoleKey = user.role.key as ROLES_KEYS;
        /**
         * if router.url.length === 1 - we should redirect user to some of the views
         * if router.url.length > 1  - user has already correct URL and no redirects
         *
         * this is only for cases where user enters the app
         * - and is already logged-in
         * - and did not provide url with path
         */
        const urlLength = this.router.url.length;
        if (urlLength === 1) {
          const path = roleToPath[currentUserRoleKey];
          this.routerFacade.changeRoute({ linkParams: [path] });
          return;
        }
      });
  }

  ngAfterViewInit(): void {
    const htmlElem = this.contentWrapper?.nativeElement as HTMLElement;
    // ensure no duplicate eventListeners
    // we cannot do this in ngOnDestroy because elem. dos not exist at that point
    if (htmlElem.removeAllListeners) {
      htmlElem.removeAllListeners('scroll');
    }

    const debouncedOnScroll = debounce((event: Event) => {
      const { scrollTop } = event.target as HTMLElement;
      this.showTopbarShadow = scrollTop > 0;
    }, 10);

    // making sure that it exists
    // if not - do not interrupt user - this is only some fancy shadow below topbar
    if (htmlElem.addEventListener) {
      htmlElem.addEventListener('scroll', debouncedOnScroll);
    }
  }

  ngOnDestroy(): void {
    if (this.partnerAccountDrawerRef?.nzClosable) {
      this.partnerAccountDrawerRef.close();
    }
    this.websocket.close();
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public openGuide(guideName: GUIDE_NAME): void {
    if (this.deviceInfo.deviceTypeDetected === 'DESKTOP') {
      this.layoutService
        .startGuide(guideName, this.translateService)
        .pipe(take(1), takeUntil(this.destroy$))
        .subscribe(() => {});
    }
  }

  public goToApplication(app: UserProcess, shouldChangeRoute: boolean = true): void {
    const currActive = this.foreignerMenuItems.find(({ active }) => active === true);
    if (currActive) {
      currActive.active = false;
    }

    if (app.id === 'new') {
      this.addingNewProcess = { id: 'new' };
      this.myProcess = { id: 'new' } as UserProcess;
      if (shouldChangeRoute) {
        this.routerFacade.changeRoute({ linkParams: ['/user-process-form'] });
      }
      return;
    }

    if (app.id === 'no-items') {
      this.addingNewProcess = { id: 'no-items' };
      this.myProcess = { id: 'no-items' } as UserProcess;
      if (shouldChangeRoute) {
        this.routerFacade.changeRoute({ linkParams: ['/no-items'] });
      }
      return;
    }

    if (!app?.id || app.id === 'purchase') {
      this.addingNewProcess = { id: 'purchase' };
      this.myProcess = { id: 'purchase' } as UserProcess;
      if (shouldChangeRoute) {
        this.routerFacade.changeRoute({ linkParams: ['/purchase'] });
      }
      return;
    }

    this.addingNewProcess = null;
    if (shouldChangeRoute) {
      this.routerFacade.changeRoute({
        linkParams: ['/user-process', app.id],
        extras: {},
      });
    }
  }

  public applicationChanged({ value }: MatSelectChange): void {
    this.toggleSidebar(false);
    this.analyticsService.trackEvent('user_event', 'user_changed_application');
    this.goToApplication(value);
  }

  public showNotificationsWindow(): void {
    this.disableTitleBlinking(this.PAGE_TITLE);
    this.showNotifications = !this.showNotifications;
  }

  public logout(): void {
    this.userAuthFacade.logout();
  }

  public goTo(path: string): void {
    this.routerFacade.changeRoute({ linkParams: [path] });
  }

  public infoBarClicked(): void {
    const action = this.infoBarState.action;
    if (action === 'CALLBACK') {
      this.infoBarState.callback();
    }
  }

  public toggleSidebar(value?: boolean): void {
    if (value !== undefined) {
      this.showSidebar = value;
    } else {
      this.showSidebar = !this.showSidebar;
    }
  }

  public startSubscribeRouter() {
    this.router.events
      .pipe(
        filter(e => e instanceof NavigationStart),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.toggleSidebar(false);
      });
  }

  public async logoClicked(): Promise<void> {
    const roleKey = await lastValueFrom(
      this.myself$.pipe(
        filter(myself => !!myself?.role?.key),
        take(1),
        map(myself => myself.role.key),
        takeUntil(this.destroy$)
      )
    );

    if (roleKey === ROLES_KEYS.Foreigner) {
      return await this.foreignerLogoClicked();
    }

    const applicationsListViewForRoles = [ROLES_KEYS.Team_Leader, ROLES_KEYS.Employee, ROLES_KEYS.Admin];

    if (
      applicationsListViewForRoles.includes(roleKey as ROLES_KEYS) &&
      !this.router.url.includes('management/applications-list')
    ) {
      this.routerFacade.changeRoute({ linkParams: ['/management/applications-list'] });
      return;
    }

    if (roleKey === ROLES_KEYS.Field_Consultant && !this.router.url.includes('management/visits-list')) {
      this.routerFacade.changeRoute({ linkParams: ['/management/visits-list'] });
      return;
    }

    if (roleKey === ROLES_KEYS.Employer && !this.router.url.includes('manager/company-employees-list')) {
      this.routerFacade.changeRoute({ linkParams: ['/manager/company-employees-list'] });
      return;
    }
  }

  public openMyPartnerAccountDrawer(): void {
    this.myself$.pipe(take(1), takeUntil(this.destroy$)).subscribe(myself => {
      if (!this.allowedToParticipateInPartnerProgram) {
        return;
      }

      this.partnerAccountDrawerRef = this.myPartnerAccountDrawer.open({
        userName: myself.name,
      });
    });
  }

  private setupNotifications(): void {
    this.inAppNotifications.unreadCount$
      .pipe(
        filter(({ unreadCount }) => this.unreadNotificationsCount !== unreadCount),
        takeUntil(this.destroy$)
      )
      .subscribe(({ unreadCount }) => {
        this.unreadNotificationsCount = unreadCount;
        if (this.unreadNotificationsCount === 0) {
          this.disableTitleBlinking(this.PAGE_TITLE);
        }
      });

    merge(
      this.inAppNotifications.markAsUnreadSuccess$,
      this.inAppNotifications.markAsReadSuccess$,
      this.inAppNotifications.markAllAsReadSuccess$,
      this.inAppNotifications.markAllOfTypeAsReadSuccess$,
      this.inAppNotifications.newNotificationReceived$
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.inAppNotifications.getUnreadCount();
      });

    this.inAppNotifications.newNotificationReceived$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.newNotificationReceived = true;

      // start blinking card-title only when notificaitons window is closed
      if (!this.showNotifications) {
        const translation = this.translateService.instant('APP_TITLE.YOU_HAVE_NEW_NOTIFICATION');
        this.enableTitleBlinking(this.PAGE_TITLE, translation);
      }

      setTimeout(() => {
        this.newNotificationReceived = false;
      }, 1000);
    });

    this.inAppNotifications.getUnreadCount();
  }

  private listenForLayoutChanges(): void {
    this.layoutService.showProgressBar$
      .asObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe(val => {
        setTimeout(() => {
          this.showProgressBar = val;
        }, 50);
      });

    this.layoutService.progressPercentage$
      .asObservable()
      .pipe(
        filter(val => val !== this.progressPercentage),
        takeUntil(this.destroy$)
      )
      .subscribe(val =>
        setTimeout(() => {
          this.progressPercentage = val;
        }, 50)
      );

    this.layoutService.showBottomFixedBar$
      .asObservable()
      .pipe(
        filter(val => val !== this.showBottomFixedBar),
        takeUntil(this.destroy$)
      )
      .subscribe(val => {
        setTimeout(() => {
          this.showBottomFixedBar = val;
        }, 50);
      });

    this.layoutService.showOpenChatButton$
      .asObservable()
      .pipe(
        filter(val => val !== this.showOpenChatButton),
        takeUntil(this.destroy$)
      )
      .subscribe(val => {
        setTimeout(() => {
          this.showOpenChatButton = val;
        }, 50);
      });

    this.layoutService.infoBarState$
      .asObservable()
      .pipe(delay(1), takeUntil(this.destroy$))
      .subscribe(infoBarState => {
        setTimeout(() => {
          this.infoBarState = { ...infoBarState };
        });
      });

    this.layoutService.scrollContentTo$
      .asObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe(opts => {
        setTimeout(() => {
          const htmlContentElem = this.contentWrapper?.nativeElement as HTMLElement;
          if (!htmlContentElem?.scrollTo) {
            console.warn('Unable to scroll content because elem does not exist or method scrollTo is unavailable');
            return;
          }
          htmlContentElem.scrollTo(opts);
        }, 50);
      });

    this.layoutService.scrollIntoView$
      .asObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe(opts => {
        setTimeout(() => {
          const htmlContentElem = this.contentWrapper?.nativeElement as HTMLElement;
          if (!htmlContentElem?.scrollTo) {
            console.warn('Unable to scroll content because elem does not exist or method scrollTo is unavailable');
            return;
          }
          const elToScrollTo = document.getElementById(opts.htmlElemID);
          if (!elToScrollTo?.scrollIntoView === null) {
            return;
          }
          elToScrollTo.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, 50);
      });
  }

  private enableTitleBlinking(val1: string, val2: string): void {
    if (this.titleBlinkingInterval) {
      clearInterval(this.titleBlinkingInterval);
      this.titleBlinkingInterval = null;
    }

    this.titleBlinkingInterval = setInterval(() => {
      const currentTitle = this.titleService.getTitle();
      const titleToSet = currentTitle === val1 ? val2 : val1;
      this.titleService.setTitle(titleToSet);
    }, 1500);
  }

  private disableTitleBlinking(titleToSet: string): void {
    if (this.titleBlinkingInterval) {
      clearInterval(this.titleBlinkingInterval);
      this.titleBlinkingInterval = null;
    }

    this.titleService.setTitle(titleToSet);
  }

  private async foreignerLogoClicked(): Promise<void> {
    // if foreigner is at route: user-process/[userProcessId] -> do nothing
    if (this.router.url.includes('user-process')) {
      return;
    }

    // if foreigner is somewhere else (settings / purchase) -> navigate to first application in collection of his applications
    const myProcesses = await lastValueFrom(
      this.myProcesses$.pipe(
        filter(myProcesses => myProcesses !== null),
        take(1),
        takeUntil(this.destroy$)
      )
    );

    if (myProcesses?.length) {
      this.goToApplication(myProcesses[0]);
      return;
    }

    // this is the case where foreigner does not have any application(process) started
    // he/she must go to the purchase to buy atleast 1.
    this.goToApplication({ id: 'purchase' } as UserProcess, true);
  }
}
